import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Modal from 'react-responsive-modal'
import { AllSparkContext } from '../../..'
import { DeploymentModel } from '../Model/AllSparkModel'
import { useGlobalStateContext } from '../../../GlobalState'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import styles from './Languages.module.scss'
import LanguagesToolbar from './LanguagesToolbar'
import LanguagesList from './LanguagesList'
import NewLanguage from './NewLanguage'
import cx from 'classnames'
import ConfirmDialog from '../shared/ConfirmDialog'
import { Logger } from '../../utils/Logger'

type LanguagesState = {
    languages: string[]
    deployments: DeploymentModel[]
    selectedLanguages: string[] | undefined
    isWorking: boolean
    isConfirmDeleteOpen: boolean
    isValidating: boolean
    isAddOpen: boolean
    canBeDeleted: boolean
    completedMessage: string
    isFinished: boolean
}

const Languages: FunctionComponent = () => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const {
        globalState: { selectedLanguage },
        setGlobalState,
    } = useGlobalStateContext()

    const [state, setState] = useState<LanguagesState>({
        languages: [],
        deployments: [],
        selectedLanguages: undefined,
        isWorking: false,
        isConfirmDeleteOpen: false,
        isValidating: false,
        isAddOpen: false,
        canBeDeleted: true,
        completedMessage: '',
        isFinished: false,
    })

    const onGetData = useCallback(async () => {
        getLanguages()
        getDeployments()
    }, [api, setGlobalState, throwError])

    const getDeployments = async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            const deployments = await api.getDeployments()
            if (deployments) {
                setState((prev) => ({ ...prev, deployments: deployments }))
            }
        } catch (error) {
            Logger.logError(error)
        }
    }

    const getLanguages = async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            const languages = await api.getLanguages()
            if (languages && languages?.length > 0) {
                setState((prev) => ({
                    ...prev,
                    languages: languages.sort(),
                }))
            } else {
                setState((prev) => ({ ...prev, languages: [] }))
            }
        } catch (error) {
            Logger.logError('Exception : Unable to load data. Check if the backend is running !!', error)
            setState((prev) => ({ ...prev, isWorking: false }))
            //throwError(error)
        } finally {
            setState((prev) => ({ ...prev, isWorking: false }))
        }
    }

    // load data on mount
    useEffect(() => {
        onGetData()
    }, [api, onGetData])

    const closeIcon = () => (state.isWorking || state.isValidating ? <div /> : '')

    const onSelectedLanguageChange = async (language: string) => {
        setState((prev) => ({
            ...prev,
            canBeDeleted: !state.deployments.some((d) => d.deploymentRequestParameters.defaultLanguage === language),
        }))
        setGlobalState((prev) => ({ ...prev, selectedLanguage: language }))
    }

    const selectedLanguageCode = useMemo(
        () => state.languages.find((l) => l === selectedLanguage),
        [state.languages, selectedLanguage],
    )

    const onDeleteLanguage = () => {
        setState((prev) => ({ ...prev, isConfirmDeleteOpen: true }))
    }

    const onAddLanguage = () => {
        setState((prev) => ({ ...prev, isAddOpen: true }))
    }

    const onAddLanguageClose = async (change: boolean, success: boolean) => {
        setState((prev) => ({ ...prev, isAddOpen: false }))
        if (change) {
            await getLanguages()
        }
    }

    const onConfirmDeleteClose = () => {
        setState((prev) => ({ ...prev, isConfirmDeleteOpen: false, isFinished: false }))
    }

    const onConfirmDeleteCancel = () => {
        setState((prev) => ({ ...prev, isConfirmDeleteOpen: false, isFinished: false }))
    }

    const onConfirmDelete = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            if (selectedLanguage) {
                await api.deleteLanguage(selectedLanguage)
            }

            await getLanguages()

            setState((prev) => ({
                ...prev,
                isWorking: false,
                isFinished: true,
                completedMessage: `Language '` + selectedLanguageCode + `' deleted`,
                selectedLanguageCode: undefined,
            }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false, selectedLanguage: undefined }))
        } catch (error: any) {
            Logger.logError('An error occurred:', error.message)
            setState((prev) => ({
                ...prev,
                isWorking: false,
                isFinished: true,
                completedMessage: `Deleting language '` + selectedLanguageCode + `' failed. Check console for errors.`,
            }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const errorMessageModalStyle: React.CSSProperties = {
        height: '650px',
    }

    return (
        <Grid rows="auto 1fr" style={{ overflow: 'hidden' }}>
            <LanguagesToolbar
                hasItemSelected={!!selectedLanguage}
                canBeDeleted={state.canBeDeleted}
                isWorking={false}
                onRefresh={onGetData}
                onDelete={onDeleteLanguage}
                onAdd={onAddLanguage}
            />
            <Grid rows="auto 1fr" columns="350px 1fr" gap={4} className={styles.container}>
                <Grid>
                    <Heading type="heading1">Languages</Heading>
                </Grid>
                <div />
                <LanguagesList
                    languages={state.languages}
                    onSelectedChange={onSelectedLanguageChange}
                    selectedItems={selectedLanguageCode ? [selectedLanguageCode] : undefined}
                />
            </Grid>

            {state.isConfirmDeleteOpen && (
                <Modal
                    open={state.isConfirmDeleteOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!state.isWorking && !state.isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isConfirmDeleteOpen: false,
                            }))
                        }
                    }}
                >
                    <ConfirmDialog
                        height={160}
                        width={480}
                        message={"Are you sure you want to delete language '" + selectedLanguageCode + "'?"}
                        workingMessage="Deleting language"
                        completedMessage={state.completedMessage}
                        isWorking={state.isWorking}
                        isFinished={state.isFinished}
                        onClose={onConfirmDeleteClose}
                        onCancel={onConfirmDeleteCancel}
                        onConfirm={onConfirmDelete}
                    />
                </Modal>
            )}

            {state.isAddOpen && (
                <Modal
                    open={state.isAddOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!state.isWorking && !state.isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isAddOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <NewLanguage
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(change, success) => {
                            onAddLanguageClose(change, success)
                        }}
                    />
                </Modal>
            )}
        </Grid>
    )
}

export default Languages
