import styles from './DockerImagesSettings.module.scss'
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import Grid from '../../infratructure/grid/Grid'
import { AllSparkContext } from '../../..'
import Heading from '../../infratructure/heading/Heading'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import { useGlobalStateContext } from '../../../GlobalState'
import { ContainerRegistryRepositoryModel, ProductType } from '../Model/AllSparkModel'
import Button, { ButtonType } from '../../infratructure/button/Button'
import Input from '../../infratructure/input/Input'
import Select from '../../infratructure/select/Select'
import { Options } from 'react-select'
import cx from 'classnames'
import { Logger } from '../../utils/Logger'

interface DockerImagesSettingsState {
    repositories: ContainerRegistryRepositoryModel[]
    productTypes: Options<{
        label: string
        value: ProductType
    }>
    selectedProductType: { label: string; value: ProductType } | undefined
    repositoryName: string | undefined
    isWorking: boolean
}

type DockerImagesSettingsProps = {
    height: number
    width: number
}

const DockerImagesSettings: FunctionComponent<DockerImagesSettingsProps> = ({ height, width }) => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const {
        globalState: { selectedGroupId },
        setGlobalState,
    } = useGlobalStateContext()

    const [state, setState] = useState<DockerImagesSettingsState>({
        repositories: [],
        productTypes: [
            { label: ProductType[ProductType.Invision], value: ProductType.Invision },
            { label: ProductType[ProductType.Flow], value: ProductType.Flow },
        ],
        selectedProductType: undefined,
        repositoryName: undefined,
        isWorking: false,
    })

    const getData = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true }))
        try {
            let result = await api.getRegistryRepositories()
            setState((prev) => ({ ...prev, repositories: result }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false }))
        } catch (error) {
            Logger.logError('An error occurred:', error)
            setGlobalState((prev) => ({ ...prev, isSpinning: false }))
        }
    }

    const onGetData = useCallback(async () => {
        getData()
    }, [api, setGlobalState, throwError])

    // load data on mount
    useEffect(() => {
        onGetData()
    }, [api, onGetData])

    const onDelete = async (productType: string, repository: string) => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        try {
            if (productType != null && repository != null) {
                let result = await api.deleteRegistryRepository(productType, repository)
                if (result) {
                    let newRepositories = state.repositories.filter(
                        (i) => i.productType !== productType || i.repository !== repository,
                    )
                    setState((prev) => ({
                        ...prev,
                        repositories: newRepositories,
                        repositoryName: undefined,
                        selectedProductType: undefined,
                    }))
                }
            }
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } catch (error) {
            Logger.logError('An error occurred:', error)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const onSelectedProductTypeChange = async (productType: { label: string; value: ProductType }) => {
        setState((prev) => ({ ...prev, selectedProductType: productType }))
    }

    const onRepositoryNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, repositoryName: e.target.value }))
    }

    const onAdd = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((state) => ({ ...state, isWorking: true }))
        try {
            if (state.selectedProductType != null) {
                let result = await api.addRegistryRepository(state.selectedProductType.value, state.repositoryName!)
                let newRepositories = state.repositories
                newRepositories.push(result)
                setState((prev) => ({
                    ...prev,
                    repositories: newRepositories,
                    repositoryName: undefined,
                    selectedProductType: undefined,
                }))
            }
        } catch (error) {
            Logger.logError('An error occurred:', error)
        } finally {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            setState((state) => ({ ...state, isWorking: false }))
        }
    }

    const repositoryExists = () => {
        return state.repositories.some(
            (r) => r.productType === state.selectedProductType?.label && r.repository === state.repositoryName,
        )
    }

    const isFormValuesValid =
        Boolean(state.selectedProductType?.value) && Boolean(state.repositoryName) && !repositoryExists()

    return (
        <Grid rows="auto auto 1fr" columns="150px auto 105px" style={{ height: height, width: width }} gap={20}>
            <Grid columnSpan={3}>
                <Heading type="heading1">Settings</Heading>
            </Grid>
            <Grid gap={4}>
                <Heading
                    type="normal"
                    className={cx({
                        [styles.requiredInput]: !Boolean(state.selectedProductType?.value),
                    })}
                >
                    Product Type
                </Heading>
                <Select
                    headerText=""
                    isClearable={false}
                    options={state.productTypes}
                    value={state.selectedProductType?.value}
                    onChange={onSelectedProductTypeChange}
                    placeholder="Select Product"
                    isDisabled={state.isWorking}
                />
                <div style={{ height: '16px' }} />
            </Grid>
            <Grid>
                <Input
                    name="repository"
                    headerText="Repository"
                    value={state.repositoryName || ''}
                    onChange={onRepositoryNameChange}
                    style={{ height: 30, marginTop: 0 }}
                    className={cx({
                        [styles.requiredInput]: !Boolean(state.repositoryName),
                    })}
                    disabled={state.isWorking}
                />
                {repositoryExists() ? (
                    <div style={{ color: 'red', fontSize: '12px', fontWeight: 'bold', marginLeft: '3px' }}>
                        already exists
                    </div>
                ) : (
                    <div style={{ height: '16px' }}></div>
                )}
            </Grid>
            <Button
                className={styles.btn}
                style={{ marginTop: 20, width: 80 }}
                buttonType={ButtonType.Default}
                onClick={onAdd}
                disabled={!isFormValuesValid || state.isWorking}
            >
                Add
            </Button>

            <Grid
                rows={state.repositories.map((r, i) => '40px ')}
                columnSpan={3}
                style={{ overflowX: 'hidden', overflowY: 'auto' }}
            >
                {state.repositories.map((r, i) => (
                    <Grid
                        key={i}
                        columns={state.repositories.length > 14 ? '170px 1fr 90px' : '170px 1fr 105px'}
                        style={{ marginLeft: 3 }}
                    >
                        <div>{`${r.productType}`}</div>
                        <div>{`${r.repository}`}</div>
                        <Button
                            className={styles.btn}
                            buttonType={ButtonType.Default}
                            onClick={() => onDelete(r.productType, r.repository)}
                            style={{
                                marginRight: '25px',
                                marginLeft: state.repositories.length > 14 ? '2px' : '0',
                                alignItems: 'center',
                            }}
                        >
                            Delete
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default DockerImagesSettings
