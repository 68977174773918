import { AllSparkContext } from '../../../..'
import styles from './Edit.module.scss'
import { FunctionComponent, useContext, useState } from 'react'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import { useGlobalStateContext } from '../../../../GlobalState'
import { ProductType } from '../../Model/AllSparkModel'
import { Logger } from '../../../utils/Logger'

interface EditAzureServiceBusProps {
    deploymentId: string | undefined
    onUpdate: (isUpdating: boolean) => void
    onClose: (change: boolean, success: boolean) => void
    productType: ProductType
}

type EditAzureServiceBusState = {
    deploymentId: string
    isDeploying: boolean
    isDone: boolean
    hasErrors: boolean
}

const EditAzureServiceBus: FunctionComponent<EditAzureServiceBusProps> = ({
    deploymentId,
    onUpdate,
    onClose,
    productType,
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditAzureServiceBusState>({
        deploymentId: deploymentId || '',
        isDeploying: false,
        isDone: false,
        hasErrors: false,
    })

    const update = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isDeploying: true, hasErrors: false }))
        onUpdate(true)

        try {
            await api.updateServiceBusConnectionstrings(state.deploymentId, productType)

            setState((prev) => ({ ...prev, isDeploying: false, isDone: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } catch (error: any) {
            Logger.logError(
                `An error occurred, updating the service bus queues and topics connection strings failed: ${error.message}`,
            )
            setState((prev) => ({ ...prev, isDeploying: false, hasErrors: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    return (
        <Grid rows="auto 1fr auto" className={styles.main} gap={0}>
            <Heading type="heading1">Edit Azure ServiceBus</Heading>
            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Renew connectionstrings
            </Heading>
            <div className={styles.editcontainer}>
                <Grid gap={0} style={{ marginTop: '4px' }}>
                    <div></div>

                    {state.isDone && (
                        <Grid gap={20} className={styles.info} style={{ marginBottom: '10px', marginTop: '20px' }}>
                            Renewing queues and topic connectionstrings are finished.
                        </Grid>
                    )}

                    {state.isDeploying && (
                        <Grid
                            columns="1fr auto"
                            columnSpan={2}
                            style={{ marginBottom: '20px', marginTop: '20px', marginRight: '5px' }}
                        >
                            <div />
                            <Grid columns="1fr auto" gap={10}>
                                <Grid>Updating</Grid>
                                <Grid style={{ alignItems: 'center', marginBottom: '3px' }}>
                                    <LoaderDots color="black" />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {!state.isDeploying && !state.isDone && (
                        <Grid rows="auto auto" gap={10} style={{ marginBottom: '10px' }}>
                            <Grid gap={10}>
                                <div>
                                    The Azure ServiceBus queues and topics connectionstrings will be renewed, and the
                                    application restarted.
                                </div>
                                <Heading type="heading1" style={{ marginRight: '10px', marginTop: '0px' }}></Heading>
                                {productType === ProductType.Flow && (
                                    <Grid gap={5}>
                                        <div style={{ fontSize: 'smaller' }}>Broadcast (topic)</div>
                                        <div style={{ fontSize: 'smaller' }}>Execution (queue)</div>
                                    </Grid>
                                )}
                                {productType === ProductType.Invision && (
                                    <Grid gap={5}>
                                        <div style={{ fontSize: 'smaller' }}>Dispatcher (queue)</div>
                                        <div style={{ fontSize: 'smaller' }}>Worker (queue)</div>
                                        <div style={{ fontSize: 'smaller' }}>System Worker (queue)</div>
                                        <div style={{ fontSize: 'smaller' }}>Scheduler (queue)</div>
                                        <div style={{ fontSize: 'smaller' }}>Broadcast (topic)</div>
                                    </Grid>
                                )}
                            </Grid>

                            {state.hasErrors && (
                                <Grid gap={20} className={styles.info}>
                                    An error occurred, update failed.
                                </Grid>
                            )}

                            <Grid columns="1fr auto">
                                <div />
                                <Button className={styles.deploybtn} buttonType={ButtonType.Confirm} onClick={update}>
                                    Renew
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                    {state.isDone && (
                        <Grid columns="1fr auto" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <div />
                            <Button
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={() => onClose(!state.hasErrors, !state.hasErrors)}
                            >
                                Close
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default EditAzureServiceBus
