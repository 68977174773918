import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { AllSparkContext } from '../../../..'
import { useGlobalStateContext } from '../../../../GlobalState'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import styles from './Edit.module.scss'
import Input from '../../../infratructure/input/Input'
import cx from 'classnames'
import useAsyncError from '../../../infratructure/hooks/useAsyncError'
import Select from '../../../infratructure/select/Select'
import { Options } from 'react-select'
import { Logger } from '../../../utils/Logger'

interface EditDefaultLanguageProps {
    deploymentId: string | undefined
    defaultLanguage: string
    onUpdate: (isUpdating: boolean) => void
    onClose: (change: boolean, success: boolean) => void
}

type EditDefaultLanguageState = {
    deploymentId: string
    isDeploying: boolean
    isDone: boolean
    hasErrors: boolean
    defaultLanguage: string
    languages: string[]
    languageOptions: Options<{
        label: string
        value: string
    }>
    selectedLanguage: { label: string; value: string } | undefined
}

const EditDefaultLanguage: FunctionComponent<EditDefaultLanguageProps> = ({ deploymentId, onUpdate, onClose }) => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditDefaultLanguageState>({
        deploymentId: deploymentId || '',
        isDeploying: false,
        isDone: false,
        hasErrors: false,
        defaultLanguage: '',
        languages: [],
        languageOptions: [],
        selectedLanguage: undefined,
    })

    const onGetData = useCallback(async () => {
        getLanguages()
    }, [api, setGlobalState, throwError])

    const getLanguages = async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            const languages = await api.getLanguages()
            if (languages && languages?.length > 0) {
                setState((prev) => ({
                    ...prev,
                    languages: languages.sort(),
                    languageOptions: languages.map((l) => {
                        return { label: l, value: l }
                    }),
                }))
            } else {
                setState((prev) => ({ ...prev, languages: [] }))
            }
        } catch (error) {
            Logger.logError('Exception : Unable to load data. Check if the backend is running !!')
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        } finally {
            setState((prev) => ({ ...prev, isWorking: false }))
        }
    }

    useEffect(() => {
        onGetData()
    }, [api, onGetData])

    const update = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isDeploying: true, hasErrors: false }))
        onUpdate(true)

        try {
            const req = {
                deploymentId: state.deploymentId,
                defaultLanguage: state.defaultLanguage,
            }
            await api.updateDefaultLangauge(req)

            setState((prev) => ({ ...prev, isDeploying: false, isDone: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } catch (error: any) {
            Logger.logError(`An error occurred, updating the language failed: ${error.message}`)
            setState((prev) => ({ ...prev, isDeploying: false, hasErrors: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const onSelectedLanguageChanged = (language: { label: string; value: string }) => {
        setState((prev) => ({ ...prev, selectedLanguage: language, defaultLanguage: language.value }))
    }

    const isDefaultLanguageValid = !Boolean(state.defaultLanguage)

    return (
        <Grid rows="auto 1fr auto" className={styles.main} gap={0}>
            <Heading type="heading1">Edit Default language</Heading>
            <div className={styles.footer}>
                <Grid columns="1fr auto auto" gap={0}>
                    <div></div>

                    {state.isDone && (
                        <Grid gap={0} className={styles.info} columnSpan={3}>
                            Default language updated
                        </Grid>
                    )}

                    {state.isDeploying && (
                        <Grid columns="1fr auto" columnSpan={2} style={{ marginBottom: '20px', marginTop: '20px' }}>
                            <div />
                            <Grid columns="1fr auto" gap={10}>
                                <Grid>Updating</Grid>
                                <Grid style={{ alignItems: 'center', marginBottom: '3px' }}>
                                    <LoaderDots color="black" />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {state.hasErrors && (
                        <Grid gap={20} className={styles.info}>
                            An error occurred, update failed
                        </Grid>
                    )}

                    {!state.isDeploying && !state.isDone && (
                        <Grid rows="auto auto" columns="1fr auto" columnSpan={3} gap={20}>
                            <Grid columnSpan={2}>
                                <Select
                                    headerText=""
                                    isClearable={false}
                                    options={state.languageOptions}
                                    value={state.selectedLanguage?.value}
                                    onChange={onSelectedLanguageChanged}
                                    placeholder="Select language"
                                    isDisabled={state.isDeploying}
                                />
                            </Grid>
                            <div />
                            <Button
                                style={{ marginBottom: '10px' }}
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={update}
                                disabled={isDefaultLanguageValid}
                            >
                                Save
                            </Button>
                        </Grid>
                    )}

                    {state.isDone && (
                        <Grid columns="1fr auto" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <div />
                            <Button
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={() => onClose(true, !state.hasErrors)}
                            >
                                Close
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default EditDefaultLanguage
