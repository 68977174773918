import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { AllSparkContext } from '../../../..'
import {
    AppDeployment,
    Deployment,
    DeploymentModel,
    DeploymentRequest,
    EnvironmentModel,
    FailedDeploymentModel,
    FlowInstanceModel,
    Permission,
    ProductModel,
    ProductType,
    State,
    StateToStringMap,
    Status,
    StatusToStringMap,
    Tenant,
    ValidateReponse,
    VersionInfo,
} from '../../Model/AllSparkModel'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import useInterval from '../../../infratructure/hooks/useInterval'
import Input from '../../../infratructure/input/Input'
import styles from './NewDeployment.module.scss'
import cx from 'classnames'
import ErrorIcon from '../../../icons/ErrorIcon'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import Select, { OptionType } from '../../../infratructure/select/Select'
import useAsyncError from '../../../infratructure/hooks/useAsyncError'
import { useGlobalStateContext } from '../../../../GlobalState'
import { ArrayUtil } from '../../../utils/ArrayUtil'
import { Util } from './../../..//utils/Util'
import { Options } from 'react-select'
import { Logger } from '../../../utils/Logger'

interface NewDeploymentProps {
    onValidate: (isValidating: boolean) => void
    onDeploy: (isDeploying: boolean) => void
    closeDialog: (deploymentModel: DeploymentModel) => void
    onError?: (log: FailedDeploymentModel) => void
    flows: FlowInstanceModel[]
}

type NewDeploymentState = {
    products: ProductModel[]
    selectedProduct: ProductModel | null
    environments: EnvironmentModel[]
    selectedEnvironment: EnvironmentModel | null
    pollId: string
    isValidating: boolean
    isDeploying: boolean
    validationResponse: ValidateReponse | null
    deployResult: Deployment | undefined
    pollInterval: number | null
    name: string
    externalId: string
    email: string
    customerName: string
    platformVersion: string
    overrideEnvAuthSettings: boolean
    rollbackOnFailure: boolean
    advanced: boolean
    appDeployment: AppDeployment
    isEmailValid: boolean
    selectedFlowInstanceId: string | undefined
    flowSubscriptionId: string | undefined
    startTime: Date
    currentTime: string
    durationTicker: number
    tenants: Tenant[]
    selectedTenant: string
    defaultLanguage: string | undefined
    languages: string[]
    languageOptions: Options<{
        label: string
        value: string
    }>
    selectedLanguage: { label: string; value: string } | null
    versions: VersionInfo[] | undefined
}

export type PermissionKeys = keyof typeof Permission
export const permissionValues = Object.keys(Permission)
    .filter((v) => isNaN(Number(v)))
    .map((k) => ({
        label: k,
        value: Permission[k as PermissionKeys],
    }))

const NewDeployment: FunctionComponent<NewDeploymentProps> = ({
    onDeploy,
    onValidate,
    closeDialog,
    onError,
    flows,
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const throwError = useAsyncError()
    const [state, setState] = useState<NewDeploymentState>({
        products: [],
        selectedProduct: null,
        environments: [],
        selectedEnvironment: null,
        pollId: '',
        isValidating: false,
        isDeploying: false,
        validationResponse: null,
        deployResult: undefined,
        pollInterval: null,
        name: '',
        externalId: '',
        email: '',
        customerName: '',
        platformVersion: '',
        overrideEnvAuthSettings: false,
        rollbackOnFailure: true,
        advanced: false,
        appDeployment: {
            openIdConnectTenantId: '',
            openIdConnectAuthority: '',
            identityProviderAuthority: '',
            identityProviderCanInvite: false,
            identityProviderPermissions: Permission.None,
            designer: {
                appName: '[NA. Data contained by customer]',
                objectId: '[NA. Data contained by customer]',
                clientId: '',
                clientSecret: '[NA]',
                secretId: null,
                secretExpiry: undefined,
            },
            webApp: {
                appName: '[NA. Data contained by customer]',
                objectId: '[NA. Data contained by customer]',
                clientId: '',
                clientSecret: '',
                secretId: null,
                secretExpiry: undefined,
            },
        },
        isEmailValid: true,
        selectedFlowInstanceId: undefined,
        flowSubscriptionId: 'AllSparkDummySubscriptionId',
        startTime: new Date(),
        currentTime: '',
        durationTicker: 0,
        tenants: [],
        selectedTenant: '',
        defaultLanguage: undefined,
        languages: [],
        languageOptions: [],
        selectedLanguage: null,
        versions: [],
    })

    const getData = useCallback(async () => {
        try {
            const products = await api.getProducts(ProductType.Invision)
            const environments = await api.getEnvironments()
            const languages = await api.getLanguages()
            const versions = await api.getVersions(ProductType.All)

            setState((prev) => ({
                ...prev,
                products: products,
                environments: environments,
                languages: languages,
                languageOptions: languages.map((l) => {
                    return { label: l, value: l }
                }),
                versions: versions?.versions ? versions.versions : [],
            }))
        } catch (error) {
            Logger.logError('We failed getting products and environments !', error)
            throwError(error)
        }
    }, [api, throwError])

    useEffect(() => {
        getData()
    }, [getData])

    // polling setup
    useInterval(async () => {
        let t = state.durationTicker + 1
        setState((prev) => ({ ...prev, durationTicker: t }))
        dateBuilder()

        if (t > 4) {
            setState((prev) => ({ ...prev, isValidating: false, durationTicker: 0 }))
            const pollResult = await api.getDeployment(state.pollId)
            Logger.logInfo('deploying... =>', pollResult)

            setState((prev) => ({ ...prev, deployResult: pollResult }))

            const endDeployProcess = () => {
                setState((prev) => ({
                    ...prev,
                    pollId: '',
                    isDeploying: false,
                    pollInterval: null,
                    deployResult: undefined,
                    durationTicker: 0,
                }))
                onDeploy(false)
            }

            if (pollResult.deploymentModel) {
                // success
                endDeployProcess()
                closeDialog && closeDialog(pollResult.deploymentModel)
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            }

            if (pollResult.deploymentModel || (!pollResult.status && !pollResult.deploymentModel)) {
                endDeployProcess()

                if (pollResult.logs) {
                    setState((prev) => ({ ...prev, deployResult: pollResult }))
                    if (onError) {
                        onError(pollResult.logs)
                    }
                }
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            }
        }
    }, state.pollInterval)

    const handleDeploy = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({
            ...prev,
            isValidating: true,
            isDeploying: true,
            validationResponse: null,
            deployResult: undefined,
        }))

        onValidate(true)
        onDeploy(true)
        try {
            const newDeployment: DeploymentRequest = {
                environmentRowKey: state.selectedEnvironment?.rowKey || '',
                productRowKey: state.selectedProduct?.rowKey || '',
                platformVersion: state.selectedProduct?.platformVersion || '',
                name: state.name || '',
                externalId: state.externalId || '',
                userEmailAddress: state.email || '',
                appDeployment: state.overrideEnvAuthSettings ? state.appDeployment : undefined,
                rollbackOnFailure: state.rollbackOnFailure,
                flowInstanceId: state.selectedFlowInstanceId,
                flowSubscriptionId: state.flowSubscriptionId,
                flowTenantId: state.selectedTenant,
                defaultLanguage: state.defaultLanguage || 'no',
            }

            const response = await api.deploy(newDeployment)

            if (response && response.valid) {
                if (!state.pollInterval) {
                    setState((prev) => ({
                        ...prev,
                        pollId: response.deploymentId,
                        pollInterval: 1000, // 4000,
                        isValidating: false,
                        isDeploying: true,
                        durationTicker: 0,
                        startTime: new Date(),
                    }))
                } else {
                    setState((prev) => ({ ...prev, isDeploying: false }))
                    onDeploy(false)
                }
            } else {
                setState((prev) => ({ ...prev, validationResponse: response, isValidating: false }))
                onDeploy(false)
            }

            onValidate(false)
        } catch (error) {
            Logger.logError('An error occurred:', error)
            setState((prev) => ({ ...prev, isDeploying: false, isValidating: false }))
            onDeploy(false)
            onValidate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const showError =
        (state.validationResponse && !state.validationResponse.valid) ||
        state.validationResponse?.errorMessage ||
        state.deployResult?.logs?.logs

    let feedbackMsg =
        state.validationResponse?.errorMessage || state.deployResult?.logs?.logs
            ? 'Deployment failed! See the failed deployments UI for details.'
            : undefined

    const showFlowDetails = state.selectedFlowInstanceId !== '' && state.selectedFlowInstanceId !== undefined
    const showValidating = state.isValidating && !showError
    const showDeployingDetails = !state.isValidating && !showError && state.isDeploying

    const dateBuilder = () => {
        const now = new Date()
        let durationMs = now.valueOf() - state.startTime.valueOf()
        setState((prev) => ({ ...prev, currentTime: Util.millisecondsToReadableShortDuration(Math.abs(durationMs)) }))
    }

    const renderValidating = () => {
        return (
            <Grid columns="auto 1fr" gap={16} style={{ margin: '10px 0 15px 10px' }}>
                <Heading type="normal">Validating</Heading>
                <LoaderDots color="black" />
            </Grid>
        )
    }

    const renderProgressDetails = () => {
        const isRunning = (currentState: State | undefined) => {
            if (
                currentState !== undefined &&
                currentState !== State.NotStarted &&
                currentState !== State.FinishedDeploying &&
                currentState !== State.FinishedRollback
            ) {
                return true
            }

            return false
        }

        return (
            <Grid
                rows="auto auto auto auto auto auto auto auto auto auto"
                columns="230px 80px 125px auto"
                style={{ margin: '10px 0 15px 10px' }}
                gap={4}
            >
                <Heading type="sub">Section</Heading>
                <Heading type="sub">Status</Heading>
                <Heading type="sub">State</Heading>
                <div />
                <Heading type="normal">Creating authorization</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.applicationRegistrations?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.applicationRegistrations?.state || State.NotStarted)}
                </Heading>
                {isRunning(state.deployResult?.status?.applicationRegistrations?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Creating Container</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.containerRegistration?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.containerRegistration?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.containerRegistration?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Creating Servicebus, Queues & Topics</Heading>
                <Heading type="normal">
                    {StatusToStringMap(
                        state.deployResult?.status?.servicebusQueueAndTopicRegistrations?.status || Status.None,
                    )}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(
                        state.deployResult?.status?.servicebusQueueAndTopicRegistrations?.state || State.NotStarted,
                    )}
                </Heading>
                {isRunning(
                    state.deployResult?.status?.servicebusQueueAndTopicRegistrations?.state || State.NotStarted,
                ) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Deploying Database</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.databaseRegistration?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.databaseRegistration?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.databaseRegistration?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Deploying to Cluster</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.kubernetesDeployment?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.kubernetesDeployment?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.kubernetesDeployment?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}

                <Heading type="normal">Creating Permissions</Heading>
                <Heading type="normal">
                    {StatusToStringMap(state.deployResult?.status?.permissionsDeployment?.status || Status.None)}
                </Heading>
                <Heading type="normal">
                    {StateToStringMap(state.deployResult?.status?.permissionsDeployment?.state || State.NotStarted)}
                </Heading>

                {isRunning(state.deployResult?.status?.permissionsDeployment?.state || State.NotStarted) ? (
                    <LoaderDots color="black" />
                ) : (
                    <div />
                )}
            </Grid>
        )
    }

    const onSelectedLanguageChanged = (language: { label: string; value: string }) => {
        setState((prev) => ({
            ...prev,
            selectedLanguage: language,
            defaultLanguage: language.value,
        }))
    }

    const onSelectedProductChange = (selectedProduct: OptionType) => {
        setState((prev) => ({
            ...prev,
            selectedProduct: state.products.find((p) => p.name === selectedProduct.value) || null,
        }))
    }

    const onSelectedEnvironmentChange = (selectedEnvironment: OptionType) => {
        setState((prev) => ({
            ...prev,
            selectedEnvironment: state.environments.find((p) => p.name === selectedEnvironment.value) || null,
        }))
    }

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const onEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const onEmailBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
            isEmailValid: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value),
        }))
    }

    const onOverrideChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!state.isDeploying) {
            setState((prev) => ({
                ...prev,
                overrideEnvAuthSettings: !state.overrideEnvAuthSettings,
            }))
        }
    }

    const onRollbackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!state.isDeploying) {
            setState((prev) => ({
                ...prev,
                rollbackOnFailure: !state.rollbackOnFailure,
            }))
        }
    }

    const onCanInviteChange = (canInvite: OptionType) => {
        setState((prev) => ({
            ...prev,
            appDeployment: {
                ...prev.appDeployment,
                identityProviderCanInvite: !Boolean(state.appDeployment?.identityProviderCanInvite),
            },
        }))
    }

    const onSelectedPermissionChange = (selectedPermission: OptionType) => {
        setState((prev) => ({
            ...prev,
            appDeployment: {
                ...prev.appDeployment,
                identityProviderPermissions: selectedPermission.value as Permission,
            },
        }))
    }

    const onAppDeploymentTenantIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            appDeployment: {
                ...prev.appDeployment,
                identityProviderAuthority: 'https://login.microsoftonline.com/' + e.target.value,
                openIdConnectAuthority: 'https://login.microsoftonline.com/' + e.target.value + '/v2.0',
                openIdConnectTenantId: e.target.value,
            },
        }))
    }

    const onAppDeploymentWebappInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            appDeployment: {
                ...prev.appDeployment,
                webApp: {
                    ...prev.appDeployment.webApp,
                    [e.target.name]: e.target.value,
                },
            },
        }))
    }

    const onAppDeploymentDesignerClientIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            appDeployment: {
                ...prev.appDeployment,
                designer: {
                    ...prev.appDeployment.designer,
                    clientId: e.target.value,
                },
            },
        }))
    }

    const onAdvancedChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (state.advanced) {
            state.overrideEnvAuthSettings = false
        }

        setState((prev) => ({ ...prev, advanced: !state.advanced }))
    }

    const onSelectedFlowInstanceChange = async (e: OptionType) => {
        if (e === null || e === undefined) {
            setState((prev) => ({ ...prev, selectedFlowInstanceId: undefined }))
        } else {
            const tenants = await api.getFlowTenants(e.value.toString())
            setState((prev) => ({ ...prev, selectedFlowInstanceId: e.value.toString(), tenants: tenants }))
        }
    }

    const isFlowConnectionValid = () => {
        if (state.selectedFlowInstanceId !== '' && state.selectedFlowInstanceId !== undefined) {
            return Boolean(state.flowSubscriptionId)
        }

        return true
    }

    const onTenantChange = (tenant: OptionType) => {
        setState((prev) => ({ ...prev, selectedTenant: tenant.value.toString() }))
    }

    const isTenantValid = !state.selectedFlowInstanceId ? true : state.tenants?.length > 0 ? state.selectedTenant : true

    const isFormValuesValid =
        Boolean(state.name) &&
        Boolean(state.selectedProduct) &&
        Boolean(state.selectedEnvironment) &&
        Boolean(state.email) &&
        Boolean(state.defaultLanguage) &&
        isTenantValid &&
        isFlowConnectionValid()

    const renderNewDeploymentForm = () => {
        return (
            <Grid className={styles.main}>
                <Input
                    name="name"
                    autoFocus={true}
                    headerText={'Invision Instance Name'}
                    placeholder="Enter an Invision Instance name"
                    style={{ width: 'inherit', marginBottom: '20px' }} // fixes bug? in main source
                    value={state.name}
                    onChange={onInputChange}
                    className={cx({
                        [styles.requiredInput]: !Boolean(state.name),
                    })}
                    readOnly={state.isDeploying}
                />

                <Grid gap={4} style={{ marginBottom: '20px' }}>
                    <Heading
                        type="normal"
                        className={cx({
                            [styles.requiredInput]: !Boolean(state.defaultLanguage),
                        })}
                    >
                        Default language
                    </Heading>
                    <Select
                        isClearable={false}
                        options={state.languageOptions}
                        value={state.defaultLanguage}
                        onChange={onSelectedLanguageChanged}
                        placeholder="Select language"
                        isDisabled={state.isDeploying}
                    />
                </Grid>

                <Grid gap={4} style={{ marginBottom: '20px' }}>
                    <Heading
                        type="normal"
                        className={cx({
                            [styles.requiredInput]: !Boolean(state.selectedProduct),
                        })}
                    >
                        Product
                    </Heading>
                    <Select
                        isSearchable={false}
                        isClearable={false}
                        options={state.products.sort(ArrayUtil.sortByPropCompare('name', true)).map((product) => ({
                            label:
                                product.name +
                                (state.versions
                                    ? state.versions?.find((v) => v.version === product.platformVersion)?.buildType
                                        ? '-' +
                                          state.versions?.find((v) => v.version === product.platformVersion)?.buildType
                                        : ''
                                    : ''),
                            value: product.name,
                        }))}
                        value={state.selectedProduct?.name}
                        onChange={onSelectedProductChange}
                        placeholder="Select product"
                        isDisabled={state.isDeploying}
                    />
                </Grid>

                <Grid gap={4} style={{ marginBottom: '20px' }}>
                    <Heading
                        type="normal"
                        className={cx({
                            [styles.requiredInput]: !Boolean(state.selectedEnvironment),
                        })}
                    >
                        Environment
                    </Heading>
                    <Select
                        isClearable={false}
                        options={state.environments
                            .sort(ArrayUtil.sortByPropCompare('name', true))
                            .map((environment) => ({
                                label: environment.name + ' (' + environment.clusterController + ')',
                                value: environment.name,
                            }))}
                        value={state.selectedEnvironment?.name}
                        onChange={onSelectedEnvironmentChange}
                        placeholder="Select environment"
                        isDisabled={state.isDeploying}
                    />
                </Grid>

                <Input
                    name="email"
                    type="email"
                    value={state.email}
                    onChange={onEmailInputChange}
                    onBlur={onEmailBlur}
                    headerText={'Administrator Email'}
                    placeholder="Enter the administrator email"
                    style={{ width: 'inherit', marginBottom: state.isEmailValid ? '20px' : '0px' }} // fixes bug? in main source
                    className={cx({
                        [styles.requiredInput]: !Boolean(state.email),
                    })}
                    readOnly={state.isDeploying}
                />
                {!state.isEmailValid && (
                    <span style={{ marginBottom: '20px' }} className={cx(styles.invalid)}>
                        E-Mail is not in a valid format. Please provide a valid E-Mail
                    </span>
                )}

                <Grid gap={4}>
                    <Heading type="normal">Connect to Flow</Heading>
                    <Select
                        isClearable={true}
                        options={flows.sort(ArrayUtil.sortByPropCompare('name', true)).map((flow) => ({
                            label: flow.name,
                            value: flow.deploymentId,
                        }))}
                        value={state.selectedFlowInstanceId}
                        onChange={onSelectedFlowInstanceChange}
                        placeholder="Select Flow Instance"
                        isDisabled={state.isDeploying}
                    />
                </Grid>
                {showFlowDetails && state.tenants?.length > 0 && (
                    <Grid style={{ width: 'inherit', marginTop: '20px' }}>
                        <Heading
                            type="normal"
                            className={cx({
                                [styles.requiredInput]: !Boolean(state.selectedTenant),
                            })}
                        >
                            Tenant Id
                        </Heading>
                        <Select
                            isSearchable={false}
                            isClearable={false}
                            options={state.tenants.sort(ArrayUtil.sortByPropCompare('name', true)).map((tenant) => ({
                                label: tenant.name + ' (' + tenant.id + ')',
                                value: tenant.id,
                            }))}
                            value={state.selectedTenant}
                            onChange={onTenantChange}
                            placeholder={'Select Tenant Id'}
                            isDisabled={state.isDeploying}
                        />
                    </Grid>
                )}
                {showFlowDetails && state.tenants?.length === 0 && state.selectedFlowInstanceId && (
                    <div style={{ marginTop: '2px', fontSize: 'smaller', fontWeight: '500', fontStyle: 'italic' }}>
                        The Flow Instance has no tenants. A new will be created for you.
                    </div>
                )}
                {showFlowDetails &&
                    (!state.flowSubscriptionId ? (
                        <Grid>
                            <Input
                                name="flowSubscriptionId"
                                type="flowSubscriptionId"
                                value={state.flowSubscriptionId}
                                onChange={onInputChange}
                                headerText={'Flow Subscription Id'}
                                placeholder="Flow Subscription Id"
                                style={{ width: 'inherit', marginBottom: '20px' }}
                                readOnly={state.isDeploying}
                                className={cx({
                                    [styles.requiredInput]: !Boolean(state.flowSubscriptionId),
                                })}
                            />
                        </Grid>
                    ) : (
                        ''
                    ))}

                <Grid style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <Input
                        name="externalId"
                        type="externalId"
                        value={state.externalId}
                        onChange={onInputChange}
                        headerText={'External Id'}
                        placeholder="External Id"
                        style={{ width: 'inherit' }}
                        readOnly={state.isDeploying}
                    />
                </Grid>

                <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center', marginBottom: '20px' }}>
                    <Input
                        name="advanced"
                        type="checkbox"
                        checked={state.advanced}
                        onChange={onAdvancedChanged}
                        style={{ width: '16px', margin: 0, height: '16px' }}
                        readOnly={state.isDeploying}
                    />
                    <Heading type="normal">Advanced</Heading>
                </Grid>

                {state.advanced && (
                    <Grid columns="auto" gap={10} style={{ alignItems: 'center' }}>
                        <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center' }}>
                            <Input
                                name="rollback"
                                type="checkbox"
                                checked={state.rollbackOnFailure}
                                onChange={onRollbackChange}
                                style={{ width: '16px', margin: 0, height: '16px' }}
                                readOnly={state.isDeploying}
                            />
                            <Heading type="normal">Rollback on deployment failure</Heading>
                        </Grid>

                        <Grid />

                        <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center' }}>
                            <Input
                                name="override"
                                type="checkbox"
                                checked={state.overrideEnvAuthSettings}
                                onChange={onOverrideChange}
                                style={{ width: '16px', margin: 0, height: '16px' }}
                                readOnly={state.isDeploying}
                            />
                            <Heading type="normal">Override Environments' User authentication scheme</Heading>
                        </Grid>

                        {state.overrideEnvAuthSettings && (
                            <Grid gap={20}>
                                <Grid />

                                <Grid gap={4}>
                                    <Heading type="normal">Identity Provider Permissions</Heading>
                                    <Select
                                        isClearable={false}
                                        options={permissionValues}
                                        value={state.appDeployment.identityProviderPermissions}
                                        onChange={onSelectedPermissionChange}
                                        placeholder="Select permission"
                                        isDisabled={state.isDeploying}
                                    />
                                </Grid>

                                <Grid gap={4}>
                                    <Heading type="normal">Identity Provider Can Invite</Heading>
                                    <Select
                                        isClearable={false}
                                        options={[
                                            { value: 1, label: 'Yes' },
                                            { value: 0, label: 'No' },
                                        ]}
                                        value={state.appDeployment.identityProviderCanInvite ? 1 : 0}
                                        onChange={onCanInviteChange}
                                        placeholder="Can invite"
                                        isDisabled={state.isDeploying}
                                    />
                                </Grid>

                                <Input
                                    name="openIdConnectTenantId"
                                    headerText={'OpenId Connect Tenant Id'}
                                    placeholder="Enter the OpenId Connect Tenant Id"
                                    style={{ width: 'inherit' }}
                                    value={state.appDeployment?.openIdConnectTenantId}
                                    onChange={onAppDeploymentTenantIdChange}
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.appDeployment?.openIdConnectTenantId),
                                    })}
                                    readOnly={state.isDeploying}
                                />

                                <Input
                                    name="clientId"
                                    headerText={'Client Id Webapp'}
                                    placeholder="Enter Client Id Webapp"
                                    style={{ width: 'inherit' }}
                                    value={state.appDeployment?.webApp.clientId}
                                    onChange={onAppDeploymentWebappInputChange}
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.appDeployment?.webApp.clientId),
                                    })}
                                    readOnly={state.isDeploying}
                                />

                                <Input
                                    name="clientSecret"
                                    headerText={'Client Secret Webapp'}
                                    placeholder="Enter Client Secret Webapp"
                                    style={{ width: 'inherit' }}
                                    value={state.appDeployment?.webApp.clientSecret}
                                    onChange={onAppDeploymentWebappInputChange}
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.appDeployment?.webApp.clientSecret),
                                    })}
                                    readOnly={state.isDeploying}
                                />

                                <Input
                                    name="secretExpiry"
                                    headerText={'Client Secret Webapp Expiry'}
                                    type="datetime-local"
                                    style={{ width: 'inherit' }}
                                    value={state.appDeployment?.webApp.secretExpiry}
                                    onChange={onAppDeploymentWebappInputChange}
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.appDeployment?.webApp.secretExpiry),
                                    })}
                                    readOnly={state.isDeploying}
                                />

                                <Input
                                    name="clientIdDesigner"
                                    headerText={'Client Id Designer'}
                                    placeholder="Enter Client Id Designer"
                                    style={{ width: 'inherit' }}
                                    value={state.appDeployment?.designer.clientId}
                                    onChange={onAppDeploymentDesignerClientIdChange}
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.appDeployment?.designer.clientId),
                                    })}
                                    readOnly={state.isDeploying}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center' }}>
                    {showError && <ErrorIcon />}
                    <span style={{ fontSize: '0.7em' }}>{feedbackMsg}</span>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid rows="auto 1fr auto">
            <Heading type="heading1">New Invision Instance</Heading>
            <Grid rows="1fr auto auto">
                {renderNewDeploymentForm()}
                <Heading type="heading1" style={{ visibility: state.isDeploying ? 'visible' : 'hidden' }}>
                    {!showError && 'Progress'}
                    {showDeployingDetails && ' (' + state.currentTime + ')'}
                </Heading>
                {showValidating && renderValidating()}
                {showDeployingDetails && renderProgressDetails()}
            </Grid>
            <div className={styles.footer}>
                <Grid columns="1fr auto" gap={8}>
                    <div></div>
                    {!state.isDeploying && !showError && (
                        <Button
                            className={styles.deploybtn}
                            buttonType={ButtonType.Confirm}
                            onClick={handleDeploy}
                            disabled={!isFormValuesValid}
                        >
                            Let's Go !
                        </Button>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default NewDeployment
