import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import styles from './Products.module.scss'
import ProductList from './ProductList'
import ButtonNavigator from '../../infratructure/buttonNavigator/ButtonNavigator'
import ProductDetails from './ProductDetails'
import { DeploymentModel, FlowInstanceModel, ProductModel, ProductType, VersionInfo } from '../Model/AllSparkModel'
import { AllSparkContext } from '../../..'
import ProductToolbar from './ProductToolbar'
import Spinner from '../../infratructure/spinner/Spinner'
import Modal from 'react-responsive-modal'
import NewProduct from './new/NewProduct'
import { ArrayUtil } from '../../utils/ArrayUtil'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import DeleteProductConfirmation from './delete/DeleteProductConfirmation'
import { useGlobalStateContext } from '../../../GlobalState'
import { Logger } from '../../utils/Logger'

type ProductsState = {
    products: ProductModel[]
    isWorking: boolean
    isNewOpen: boolean
    isDeleteConfirmationOpen: boolean
    hasErrors: boolean
    deleteErrorMessage: string | undefined
    flows: FlowInstanceModel[] | undefined
    invisions: DeploymentModel[] | undefined
    versions: VersionInfo[] | undefined
    buildType: string | undefined
    canDelete: boolean
}

const Products: FunctionComponent = () => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()

    const {
        globalState: { selectedProductId },
        setGlobalState,
    } = useGlobalStateContext()

    const [
        {
            products,
            isWorking,
            isNewOpen,
            isDeleteConfirmationOpen,
            hasErrors,
            deleteErrorMessage,
            flows,
            invisions,
            versions,
            buildType,
            canDelete,
        },
        setState,
    ] = useState<ProductsState>({
        products: [],
        isWorking: false,
        isNewOpen: false,
        isDeleteConfirmationOpen: false,
        hasErrors: false,
        deleteErrorMessage: undefined,
        flows: undefined,
        invisions: undefined,
        versions: undefined,
        buildType: undefined,
        canDelete: false,
    })

    const selectedProduct = useMemo(
        () => products.find((prod) => prod.rowKey === selectedProductId),
        [products, selectedProductId],
    )

    const onGetData = useCallback(async () => {
        setState((prev) => ({ ...prev, isWorking: true }))

        try {
            const flows = await api.getFlowDeployments()
            const invisions = await api.getDeployments()
            const products = await api.getProducts(ProductType.All)
            const versions = await api.getVersions(ProductType.All)
            if (products) {
                setState((prev) => ({
                    ...prev,
                    products: products.sort(ArrayUtil.sortByPropCompare('name', true)),
                    invisions: invisions ? invisions : [],
                    flows: flows ? flows : [],
                    versions: versions ? versions.versions : [],
                    isWorking: false,
                }))
            } else {
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        } catch (error) {
            Logger.logError('Exception : Unable to load data. Check if the backend is running !!')
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        }
    }, [api, throwError])

    const onConfirmedDelete = async () => {
        if (selectedProductId) {
            setState((prev) => ({
                ...prev,
                isWorking: true,
                isDeleting: true,
                hasErrors: false,
                deleteErrorMessage: undefined,
            }))

            try {
                const response = await api.deleteProduct(selectedProductId)
                if (response.success) {
                    setState((prev) => ({
                        ...prev,
                        products: products
                            .filter((o) => o.rowKey !== selectedProductId)
                            .sort(ArrayUtil.sortByPropCompare('name', true)),
                        isDeleting: false,
                        isWorking: false,
                        isDeleteConfirmationOpen: false,
                    }))

                    setGlobalState((prev) => ({
                        ...prev,
                        selectedProductId: products.length > 0 ? products[0].rowKey : undefined,
                    }))
                } else {
                    setState((prev) => ({
                        ...prev,
                        isDeleting: false,
                        isWorking: false,
                        hasErrors: true,
                        deleteErrorMessage: response.errorMessage,
                    }))
                }
            } catch (error) {
                Logger.logError('Delete failed', error)
                setState((prev) => ({
                    ...prev,
                    isDeleting: false,
                    isWorking: false,
                    isDeleteConfirmationOpen: false,
                }))

                throwError(error)
            }
        }
    }

    // load on mount
    useEffect(() => {
        onGetData()
    }, [api, onGetData])

    useEffect(() => {
        if (products.length > 0 && !products.some((p) => p.rowKey === selectedProductId)) {
            const selectedProduct = products.length > 0 ? products[0] : undefined
            const buildType = versions?.find((v) => v.version === selectedProduct?.platformVersion)?.buildType
            setState((prev) => ({ ...prev, buildType: buildType }))
            setGlobalState((prev) => ({
                ...prev,
                selectedProductId: selectedProduct?.rowKey || undefined,
            }))
        }
    }, [products, selectedProductId, setGlobalState])

    const onSelectedProductChange = (product: ProductModel) => {
        let flowsInUse = flows?.some((f) => f.productRowKey === product.rowKey) === true
        let invisionsInUse =
            invisions?.some((f) => f.deploymentRequestParameters.productRowKey === product.rowKey) === true

        const builtType = versions?.find((v) => v.version === product.platformVersion)?.buildType

        setState((prev) => ({ ...prev, canDelete: !flowsInUse && !invisionsInUse, buildType: builtType }))
        setGlobalState((prev) => ({ ...prev, selectedProductId: product.rowKey }))
    }

    const closeIcon = () => (isWorking ? <div /> : '')

    const handleAdd = (addedProduct: ProductModel) => {
        setState((prev) => ({
            ...prev,
            isNewOpen: false,
            products: [...products, addedProduct].sort(ArrayUtil.sortByPropCompare('name', true)),
        }))

        setGlobalState((prev) => ({ ...prev, selectedProductId: addedProduct.rowKey }))
    }

    const handleOnClone = async () => {
        if (selectedProduct?.rowKey) {
            try {
                setState((prev) => ({ ...prev, isWorking: true }))
                const clonedProduct = await api.cloneProduct(selectedProduct.rowKey)
                const targetIndex = products.indexOf(selectedProduct) + 1
                if (clonedProduct) {
                    setState((prev) => ({
                        ...prev,
                        products: [...products.slice(0, targetIndex), clonedProduct, ...products.slice(targetIndex)],
                        isWorking: false,
                    }))
                    setGlobalState((prev) => ({ ...prev, selectedProductId: clonedProduct.rowKey }))
                }
            } catch (error) {
                Logger.logError(error)
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        }
    }

    const handleSave = async () => {
        if (selectedProduct) {
            try {
                setState((prev) => ({ ...prev, isWorking: true }))

                const savedProduct = await api.createOrUpdateProduct(selectedProduct)

                if (savedProduct) {
                    const envs = [...products.filter((e) => e.rowKey !== savedProduct?.rowKey), savedProduct].sort(
                        ArrayUtil.sortByPropCompare('name', true),
                    )

                    setState((prev) => ({
                        ...prev,
                        isWorking: false,
                        products: envs,
                    }))
                    setGlobalState((prev) => ({ ...prev, selectedProductId: savedProduct.rowKey }))
                }
            } catch (error) {
                Logger.logError(error)
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        }
    }

    const onSelectedProductPropertyChanged = (updatedproduct: ProductModel) => {
        const index = products.findIndex((env) => env.rowKey === updatedproduct.rowKey)
        const updatedProducts = [...products.slice(0, index), updatedproduct, ...products.slice(index + 1)]

        setState((prev) => ({
            ...prev,
            products: updatedProducts,
        }))

        setGlobalState((prev) => ({ ...prev, selectedProductId: updatedproduct.rowKey }))
    }

    return (
        <>
            <Grid rows="auto 1fr">
                <ProductToolbar
                    hasItemSelected={!!selectedProductId}
                    isWorking={isWorking}
                    onAdd={() => setState((prev) => ({ ...prev, isNewOpen: true }))}
                    onRefresh={onGetData}
                    onSave={handleSave}
                    onDelete={() => setState((prev) => ({ ...prev, isDeleteConfirmationOpen: true }))}
                    onClone={handleOnClone}
                    canDelete={canDelete}
                />
                <Grid rows="auto 1fr" columns="350px 1fr" gap={4} className={styles.container}>
                    <Grid columns="1fr auto">
                        <Heading type={'heading1'}>Products</Heading>
                        {isWorking && <Spinner />}
                    </Grid>
                    <ButtonNavigator
                        spacing="compact"
                        buttons={[
                            {
                                id: 'Details',
                                caption: 'Details',
                            },
                        ]}
                        selectedButtonId={'Details'}
                        onButtonClicked={() => {}}
                    />
                    <ProductList
                        items={products}
                        onSelectedChange={onSelectedProductChange}
                        selectedItems={selectedProduct ? [selectedProduct] : undefined}
                    />
                    <ProductDetails
                        selectedProduct={selectedProduct}
                        selectedProductVersionBuildType={buildType}
                        onPropertyChange={onSelectedProductPropertyChanged}
                    />
                </Grid>
            </Grid>
            {isNewOpen && (
                <Modal
                    open={isNewOpen}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isNewOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: styles.customModal,
                    }}
                    closeIcon={closeIcon()}
                >
                    <NewProduct onAdd={handleAdd} />
                </Modal>
            )}
            {isDeleteConfirmationOpen && (
                <Modal
                    open={isDeleteConfirmationOpen}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isDeleteConfirmationOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: styles.customModal,
                    }}
                    closeIcon={closeIcon()}
                >
                    <DeleteProductConfirmation
                        isWorking={isWorking}
                        selectedProduct={selectedProduct}
                        onDelete={onConfirmedDelete}
                        errorMessage={deleteErrorMessage}
                    />
                </Modal>
            )}
        </>
    )
}

export default Products
