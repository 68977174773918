import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import { useGlobalStateContext } from '../../../GlobalState'
import { AllSparkContext } from '../../..'
import Grid from '../../infratructure/grid/Grid'
import styles from './DockerImages.module.scss'
import Select from '../../infratructure/select/Select'
import Heading from '../../infratructure/heading/Heading'
import {
    ProductType,
    ProductDockerImages,
    CheckedDockerImage,
    RegistryUsage,
    ProductRepoVersion,
} from '../Model/AllSparkModel'
import DockerImagesGroupsList from './DockerImagesGroupsList'
import { Options } from 'react-select'
import GroupVersionList from './GroupVersionList'
import DockerImagesToolbar from './DockerImagesToolbar'
import Modal from 'react-responsive-modal'
import ConfirmDialog from '../shared/ConfirmDialog'
import useInterval from '../../infratructure/hooks/useInterval'
import DockerImagesSettings from './DockerImagesSettings'
import LoaderDots from '../../infratructure/dots/LoaderDots'
import { Logger } from '../../utils/Logger'

type DockerImagesState = {
    errorMessage: string | undefined
    productVersions: ProductDockerImages | undefined
    isLoading: boolean
    isWorking: boolean
    isFinished: boolean
    productTypes: Options<{
        label: string
        value: ProductType
    }>
    selectedProductType: { label: string; value: ProductType } | undefined
    selectedGroupDockerImages: CheckedDockerImage[]
    dockerImagesChecked: CheckedDockerImage[]
    dockerImageGroupsChecked: CheckedDockerImage[]
    isConfirmDeleteOpen: boolean
    isSettingsOpen: boolean
    pollInterval: number | null
    pollId: string | null
    registryUsage: RegistryUsage | undefined
    completedMessage: string
    selectedProductRepoVersions: ProductRepoVersion[]
}

const DockerImages: FunctionComponent = () => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const {
        globalState: { selectedGroupId },
        setGlobalState,
    } = useGlobalStateContext()

    const [state, setState] = useState<DockerImagesState>({
        errorMessage: undefined,
        productVersions: undefined,
        isLoading: false,
        isWorking: false,
        isFinished: false,
        productTypes: [
            { label: ProductType[ProductType.Invision], value: ProductType.Invision },
            { label: ProductType[ProductType.Flow], value: ProductType.Flow },
        ],
        selectedProductType: undefined,
        selectedGroupDockerImages: [],
        dockerImagesChecked: [],
        dockerImageGroupsChecked: [],
        isConfirmDeleteOpen: false,
        isSettingsOpen: false,
        pollInterval: null,
        pollId: null,
        registryUsage: undefined,
        completedMessage: '',
        selectedProductRepoVersions: [],
    })

    const getDockerImages = async (productType: ProductType) => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true }))
        setState((prev) => ({
            ...prev,
            errorMessage: undefined,
            isWorking: true,
            selectedGroupDockerImages: [],
            productVersions: {
                success: true,
                errorMessage: undefined,
                productType: ProductType.All,
                versions: [],
                versionsInUse: [],
                versionGroups: [],
                repoVersions: [],
            },
        }))
        try {
            const productDockerImages = await api.getProductDockerImages(productType)
            if (productDockerImages.success) {
                let dockerImages = productDockerImages.versions
                    .map((v, i) => {
                        return {
                            version: v,
                            sorting: getSorting(v),
                            selected: false,
                            disabled: productDockerImages.versionsInUse.some((u) => u === v),
                        }
                    })
                    .sort((v, s) => v.sorting.localeCompare(s.sorting))
                    .reverse()

                dockerImages.forEach((v, i) => {
                    if (i < 7) {
                        v.disabled = true
                    }
                })
                setState((prev) => ({
                    ...prev,
                    productVersions: productDockerImages,
                    dockerImagesChecked: dockerImages,
                    dockerImageGroupsChecked: productDockerImages.versionGroups
                        .map((v) => {
                            return { version: v, sorting: getSorting(v), selected: false, disabled: false }
                        })
                        .sort((v, s) => v.sorting.localeCompare(s.sorting))
                        .reverse(),
                }))
            } else {
                setState((prev) => ({ ...prev, errorMessage: productDockerImages.errorMessage }))
            }
        } catch (error) {
            let msg = 'Exception : Unable to load data. Check console for errors.'
            Logger.logError(error)
            setState((prev) => ({ ...prev, errorMessage: msg }))
        } finally {
            setState((prev) => ({ ...prev, isWorking: false }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false }))
        }
    }

    const onGetData = useCallback(async () => {
        setState((prev) => ({ ...prev, isLoading: true }))
        let usage = await api.getRegistryUsage()
        setState((prev) => ({ ...prev, registryUsage: usage, isLoading: false }))
    }, [api, setState, throwError])

    // load data on mount
    useEffect(() => {
        onGetData()
    }, [api, onGetData])

    const onDelete = () => {
        setState((prev) => ({ ...prev, isConfirmDeleteOpen: true, isFinished: false }))
    }

    const onSelectedProductTypeChange = async (productType: { label: string; value: ProductType }) => {
        setState((prev) => ({ ...prev, selectedProductType: productType }))
        await getDockerImages(productType.value)
    }

    const onSelectedGroupChange = (g: string) => {
        setGlobalState((prev) => ({ ...prev, selectedGroupId: g }))
        setState((prev) => ({
            ...prev,
            selectedGroupDockerImages: state.dockerImagesChecked
                .filter((v) => v.version.startsWith(g))
                .map((t) => {
                    return { version: t.version, sorting: t.sorting, selected: t.selected, disabled: t.disabled }
                }),
        }))
    }

    const getSorting = (str: string) => {
        let tmp = str.replaceAll('-', '.')
        let parts = tmp.split('.')
        if (parts.length === 4) {
            let r: string = ''
            parts.forEach((p) => {
                r = r + p.padStart(5, '0')
            })
            return r
        }

        return str
    }

    const selectedGroupVersion = useMemo(
        () => state.productVersions?.versionGroups.find((group) => group === selectedGroupId),
        [state.productVersions?.versionGroups, selectedGroupId],
    )

    const onVersionGroupChecked = (item: string) => {
        let srv: ProductRepoVersion[] | undefined
        let versions = [...state.dockerImagesChecked]
        let groups = [...state.dockerImageGroupsChecked]
        let group = groups.find((g) => g.version === item)
        if (group) {
            group.selected = !group.selected
            let candidates = versions.filter((v) => v.version.startsWith(item))
            if (candidates) {
                candidates.forEach((c) => {
                    if (c) {
                        if (
                            state.dockerImagesChecked.indexOf(c) > 6 &&
                            !state.productVersions?.versionsInUse.some((u) => u === c.version)
                        ) {
                            c.selected = group!.selected
                        } else {
                            c.disabled = true
                        }
                    }
                })

                srv = state.productVersions?.repoVersions.filter((r) =>
                    versions.filter((c) => c.selected).some((s) => s.version === r.version),
                )
            }
        }

        setState((prev) => ({
            ...prev,
            dockerImageGroupsChecked: groups,
            dockerImagesChecked: versions,
            selectedProductRepoVersions: srv ? srv : [],
        }))
    }

    const onVersionChecked = (item: string) => {
        let selectedGropVersions = [...state.selectedGroupDockerImages]
        let versions = [...state.dockerImagesChecked]
        let version = versions.find((v) => v.version === item)
        if (version) {
            version.selected = !version.selected
            let selectedGroupVersion = selectedGropVersions.find((v) => v.version === item)
            if (selectedGroupVersion) {
                selectedGroupVersion.selected = !selectedGroupVersion.selected
            }
        }

        let srv = state.productVersions?.repoVersions.filter((r) =>
            versions.filter((c) => c.selected).some((s) => s.version === r.version),
        )

        setState((prev) => ({
            ...prev,
            dockerImagesChecked: versions,
            selectedGroupDockerImages: selectedGropVersions,
            selectedProductRepoVersions: srv ? srv : [],
        }))
    }

    const closeIcon = () => (state.isWorking ? <div /> : '')

    const onConfirmDeleteClose = () => {
        setState((prev) => ({ ...prev, isConfirmDeleteOpen: false }))
    }

    const onConfirmDeleteCancel = () => {
        setState((prev) => ({ ...prev, isConfirmDeleteOpen: false }))
    }
    const onConfirmDelete = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            let pollId = await api.deleteProductDockerImages(
                state.selectedProductType?.value,
                state.selectedProductRepoVersions,
            )
            if (pollId) {
                setState((prev) => ({ ...prev, pollId: pollId, pollInterval: 4000 }))
            }
        } catch (error) {
            Logger.logError('An error occurred:', error)
            setState((prev) => ({ ...prev, isDeploying: false }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    // polling setup
    useInterval(async () => {
        try {
            const pollResult = await api.checkProductDockerImagesDeleteStatus(state.pollId)
            if (pollResult?.completed) {
                if (pollResult.errorMessage != null && pollResult.errorMessage !== '') {
                    Logger.logError('Completed with errors:', pollResult.errorMessage)
                } else {
                    Logger.logInfo('Completed')
                }

                await api.completeDeleteProductDockerImages(state.pollId)

                let repoVersions = state.productVersions?.repoVersions.filter((rv) =>
                    state.selectedGroupDockerImages.some((i) => !i.selected && i.version == rv.version),
                )

                let remainingDockerImages = state.selectedGroupDockerImages.filter((i) => !i.selected)

                setState((prev) => ({
                    ...prev,
                    pollId: null,
                    pollInterval: null,
                    isWorking: false,
                    isFinished: true,
                    selectedGroupDockerImages: remainingDockerImages,
                    dockerImagesChecked: state.dockerImagesChecked.filter((i) => !i.selected),
                    productVersions: prev.productVersions
                        ? {
                              ...prev.productVersions,
                              repoVersions: repoVersions ? repoVersions : [],
                          }
                        : undefined,
                    completedMessage:
                        pollResult.errorMessage != null && pollResult.errorMessage != ''
                            ? 'Deleted ' +
                              pollResult.deleted +
                              ' images completed.' +
                              (pollResult.failed > 0 ? pollResult.failed + ' images failed to delete.' : '') +
                              ' Errors occurred, check system logs.'
                            : 'Deleted ' + pollResult.deleted + ' images completed.',
                }))
                setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            } else {
                Logger.logInfo('deleting... =>', pollResult)
            }
        } catch (error) {}
    }, state.pollInterval)

    const onSettings = () => {
        setState((prev) => ({ ...prev, isSettingsOpen: true }))
    }

    const getSizeInGiB = (size: number) => {
        return (size / 1024 / 1024 / 1024).toFixed(0)
    }

    return (
        <Grid rows="auto 1fr" style={{ overflow: 'hidden' }}>
            <DockerImagesToolbar
                hasItemSelected={state.dockerImagesChecked.some((v) => v.selected)}
                isWorking={state.isWorking}
                onDelete={onDelete}
                onSettings={onSettings}
            />
            <Grid rows="auto auto auto 1fr" columns="350px 350px 1fr" gap={4} className={styles.container}>
                <Grid columnSpan={3} columns={'auto 1fr'} style={{ marginBottom: '20px' }}>
                    <Heading type="heading1">
                        Registry usage:
                        {!state.isLoading &&
                            state.registryUsage !== undefined &&
                            state.registryUsage.currentValue &&
                            ' ' +
                                getSizeInGiB(state.registryUsage?.currentValue || 0) +
                                '/' +
                                getSizeInGiB(state.registryUsage?.limit || 0) +
                                ' GiB'}
                    </Heading>
                    {state.isLoading && (
                        <Grid style={{ marginLeft: '10px' }}>
                            <LoaderDots color="black" />
                        </Grid>
                    )}
                </Grid>
                <Grid columnSpan={3}>
                    <Heading type="heading1">
                        Docker images
                        {state.dockerImagesChecked.length > 0
                            ? ' ' +
                              state.productVersions?.repoVersions.filter((rv) =>
                                  state.dockerImagesChecked
                                      .filter((c) => c.selected)
                                      .some((s) => s.version === rv.version),
                              ).length +
                              '/' +
                              state.productVersions?.repoVersions?.filter((rv) => rv.version !== 'latest').length
                            : ''}
                    </Heading>
                </Grid>
                <Grid gap={4} style={{ width: '350px' }}>
                    <Select
                        headerText=""
                        isClearable={false}
                        options={state.productTypes}
                        value={state.selectedProductType?.value}
                        onChange={onSelectedProductTypeChange}
                        placeholder="Select Product"
                        isDisabled={state.isWorking}
                    />
                </Grid>
                {state.isWorking ? (
                    <Grid style={{ marginLeft: 5 }}>
                        <LoaderDots color="black" />
                    </Grid>
                ) : state.errorMessage != null ? (
                    <Grid style={{ marginLeft: 5, textOverflow: 'ellipsis' }}>
                        {state.errorMessage.length > 50
                            ? state.errorMessage.substring(0, 47) + '...'
                            : state.errorMessage}
                    </Grid>
                ) : (
                    <div />
                )}

                <div />
                <DockerImagesGroupsList
                    items={state.productVersions?.versionGroups}
                    selectedItems={selectedGroupVersion ? [selectedGroupVersion] : undefined}
                    versionGroupsChecked={state.dockerImageGroupsChecked}
                    onSelectedChange={onSelectedGroupChange}
                    onVersionGroupChecked={onVersionGroupChecked}
                />
                <GroupVersionList
                    repoVersions={state.productVersions?.repoVersions}
                    dockerImages={state.selectedGroupDockerImages}
                    onVersionChecked={onVersionChecked}
                />
            </Grid>

            {state.isConfirmDeleteOpen && (
                <Modal
                    open={state.isConfirmDeleteOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!state.isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isConfirmDeleteOpen: false,
                            }))
                        }
                    }}
                >
                    <div />
                    <ConfirmDialog
                        height={160}
                        width={480}
                        message="Are you sure you want to delete all these docker images?"
                        workingMessage="Deleting images. This may take a while"
                        completedMessage={state.completedMessage}
                        isWorking={state.isWorking}
                        isFinished={state.isFinished}
                        onClose={onConfirmDeleteClose}
                        onCancel={onConfirmDeleteCancel}
                        onConfirm={onConfirmDelete}
                    />
                </Modal>
            )}
            {state.isSettingsOpen && (
                <Modal
                    open={state.isSettingsOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!state.isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isSettingsOpen: false,
                            }))
                        }
                    }}
                >
                    <div />
                    <DockerImagesSettings height={700} width={800} />
                </Modal>
            )}
        </Grid>
    )
}

export default DockerImages
