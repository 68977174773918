import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import styles from './../deployments/edit/Edit.module.scss'
import css from './EditDeploymentScaling.module.scss'
import { AllSparkContext } from '../../..'
import { useGlobalStateContext } from '../../../GlobalState'
import Grid from '../../infratructure/grid/Grid'
import Button, { ButtonType } from '../../infratructure/button/Button'
import LoaderDots from '../../infratructure/dots/LoaderDots'
import Input from '../../infratructure/input/Input'
import Heading from '../../infratructure/heading/Heading'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import { Logger } from '../../utils/Logger'

interface EditDeploymentSettingsProps {
    onCancel: () => void
    onSave: () => void
    onClose: () => void
    isWorking: boolean
}

type EditDeploymentSettingsState = {
    systemWorkerMessageCount?: number
    systemWorkerMaxTaskCount?: number
    systemWorkerMemoryRequest: number
    systemWorkerMemoryLimit: number

    workerMessageCount?: number
    workerMaxTaskCount?: number
    workerMemoryRequest: number
    workerMemoryLimit: number

    dispatcherMessageCount?: number
    dispatcherMaxTaskCount?: number
    dispatcherMemoryRequest: number
    dispatcherMemoryLimit: number

    schedulerMemoryRequest: number
    schedulerMemoryLimit: number

    webAppMemoryRequest: number
    webAppMemoryLimit: number

    isDone: boolean
    isWorking: boolean
    hasErrors: boolean
}

const EditDeploymentSettings: FunctionComponent<EditDeploymentSettingsProps> = ({
    onCancel,
    onSave,
    onClose,
    isWorking,
}) => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditDeploymentSettingsState>({
        systemWorkerMessageCount: 0,
        systemWorkerMaxTaskCount: 0,
        systemWorkerMemoryRequest: 0,
        systemWorkerMemoryLimit: 0,

        workerMessageCount: 0,
        workerMaxTaskCount: 0,
        workerMemoryRequest: 0,
        workerMemoryLimit: 0,

        dispatcherMessageCount: 0,
        dispatcherMaxTaskCount: 0,
        dispatcherMemoryRequest: 0,
        dispatcherMemoryLimit: 0,

        schedulerMemoryRequest: 0,
        schedulerMemoryLimit: 0,

        webAppMemoryRequest: 0,
        webAppMemoryLimit: 0,

        isDone: false,
        isWorking: isWorking,
        hasErrors: false,
    })

    const onGetData = useCallback(async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: false }))

        try {
            const settings = await api.getDefaultDeploymentSettings()
            if (settings) {
                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    systemWorkerMessageCount: settings.scaling.systemWorker.messageCount,
                    systemWorkerMaxTaskCount: settings.scaling.systemWorker.maxTaskCount,
                    systemWorkerMemoryRequest: settings.scaling.systemWorker.memory.request,
                    systemWorkerMemoryLimit: settings.scaling.systemWorker.memory.limit,

                    workerMessageCount: settings.scaling.worker.messageCount,
                    workerMaxTaskCount: settings.scaling.worker.maxTaskCount,
                    workerMemoryRequest: settings.scaling.worker.memory.request,
                    workerMemoryLimit: settings.scaling.worker.memory.limit,

                    dispatcherMessageCount: settings.scaling.dispatcher.messageCount,
                    dispatcherMaxTaskCount: settings.scaling.dispatcher.maxTaskCount,
                    dispatcherMemoryRequest: settings.scaling.dispatcher.memory.request,
                    dispatcherMemoryLimit: settings.scaling.dispatcher.memory.limit,

                    schedulerMemoryRequest: settings.scaling.scheduler.memory.request,
                    schedulerMemoryLimit: settings.scaling.scheduler.memory.limit,

                    webAppMemoryRequest: settings.scaling.webApp.memory.request,
                    webAppMemoryLimit: settings.scaling.webApp.memory.limit,
                }))
            } else {
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        } catch (error) {
            Logger.logError(
                'Exception : Unable to load data. Press refresh in the browser or check if the backend is running !!',
                error,
            )
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        } finally {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }, [api, setGlobalState, throwError])

    // load data on mount
    useEffect(() => {
        onGetData()
    }, [onGetData])

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSave = async () => {
        onSave()
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        try {
            setState((prev) => ({ ...prev, isWorking: true }))
            let request = {
                scaling: {
                    dispatcher: {
                        messageCount: state.dispatcherMessageCount,
                        maxTaskCount: state.dispatcherMaxTaskCount,
                        memory: {
                            request: state.dispatcherMemoryRequest,
                            limit: state.dispatcherMemoryLimit,
                        },
                    },
                    worker: {
                        messageCount: state.workerMessageCount,
                        maxTaskCount: state.workerMaxTaskCount,
                        memory: {
                            request: state.workerMemoryRequest,
                            limit: state.workerMemoryLimit,
                        },
                    },
                    systemWorker: {
                        messageCount: state.systemWorkerMaxTaskCount,
                        maxTaskCount: state.systemWorkerMaxTaskCount,
                        memory: {
                            request: state.systemWorkerMemoryRequest,
                            limit: state.systemWorkerMemoryLimit,
                        },
                    },
                    scheduler: {
                        memory: {
                            request: state.schedulerMemoryRequest,
                            limit: state.schedulerMemoryLimit,
                        },
                    },
                    webApp: {
                        memory: {
                            request: state.webAppMemoryRequest,
                            limit: state.webAppMemoryLimit,
                        },
                    },
                },
            }

            await api.setDefaultDeploymentSettings(request)

            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            setState((prev) => ({ ...prev, isDone: true, isWorking: false }))
        } catch (error) {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
            setState((prev) => ({ ...prev, isDone: false, isWorking: false, hasErrors: true }))
        }
    }

    return (
        <Grid
            rows="auto auto auto auto auto auto auto auto auto auto auto auto 1fr"
            gap={0}
            style={{ height: '650px', marginLeft: '10px' }}
        >
            <Heading type="heading1" style={{ marginTop: '20px' }}>
                Edit Default Deployment Settings
            </Heading>
            <Heading type="heading2" style={{ marginTop: '20px' }}>
                System Worker
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Message Count limit before scaling up</span>
                <Input
                    name="systemWorkerMessageCount"
                    value={state.systemWorkerMessageCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Max concurrent jobs</span>
                <Input
                    name="systemWorkerMaxTaskCount"
                    value={state.systemWorkerMaxTaskCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="systemWorkerMemoryRequest"
                    value={state.systemWorkerMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="systemWorkerMemoryLimit"
                    value={state.systemWorkerMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Worker
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Message Count limit before scaling up</span>
                <Input
                    name="workerMessageCount"
                    value={state.workerMessageCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Max concurrent jobs</span>
                <Input
                    name="workerMaxTaskCount"
                    value={state.workerMaxTaskCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="workerMemoryRequest"
                    value={state.workerMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="workerMemoryLimit"
                    value={state.workerMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Dispatcher
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Message Count limit before scaling up</span>
                <Input
                    name="dispatcherMessageCount"
                    value={state.dispatcherMessageCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Max concurrent jobs</span>
                <Input
                    name="dispatcherMaxTaskCount"
                    value={state.dispatcherMaxTaskCount}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="dispatcherMemoryRequest"
                    value={state.dispatcherMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="dispatcherMemoryLimit"
                    value={state.dispatcherMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Scheduler
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="schedulerMemoryRequest"
                    value={state.schedulerMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="schedulerMemoryLimit"
                    value={state.schedulerMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Heading type="heading2" style={{ marginTop: '20px' }}>
                WebApp
            </Heading>
            <Grid
                rows="auto auto auto 1fr"
                columns="400px 100px"
                gap={5}
                style={{ marginTop: '15px', marginLeft: '10px' }}
            >
                <span className={css.label}>Service Memory Request (Mi)</span>
                <Input
                    name="webAppMemoryRequest"
                    value={state.webAppMemoryRequest}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
                <span className={css.label}>Service Memory Limit (Mi)</span>
                <Input
                    name="webAppMemoryLimit"
                    value={state.webAppMemoryLimit}
                    onChange={onInputChange}
                    className={css.edit_input}
                    disabled={state.isWorking || state.isDone}
                />
            </Grid>

            <Grid
                columns="1fr auto auto auto auto"
                style={{ height: '60px', marginTop: '20px', marginRight: '10px' }}
                gap={10}
            >
                {state.isWorking && !state.isDone ? (
                    <Grid columns="auto auto 1fr" style={{ height: '30px' }} gap={10}>
                        <Grid style={{ alignItems: 'center' }}>Updating</Grid>
                        <Grid style={{ alignItems: 'center' }}>
                            <LoaderDots color="black" />
                        </Grid>
                    </Grid>
                ) : (
                    <div />
                )}
                {!state.isWorking && state.isDone ? (
                    <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                        <Grid style={{ alignItems: 'center' }}>Done!</Grid>
                        <div />
                    </Grid>
                ) : (
                    <div />
                )}
                {state.hasErrors ? (
                    <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                        <Grid style={{ alignItems: 'center' }}>Failed! Check System Log for details.</Grid>
                        <div />
                    </Grid>
                ) : (
                    <div />
                )}
                {!state.isDone ? (
                    <Button
                        className={styles.deploybtn}
                        buttonType={ButtonType.Default}
                        onClick={onCancel}
                        disabled={state.isWorking}
                    >
                        Cancel
                    </Button>
                ) : (
                    <div />
                )}
                <Button
                    className={styles.deploybtn}
                    buttonType={ButtonType.Confirm}
                    onClick={state.isDone ? onClose : handleSave}
                    disabled={state.isWorking}
                >
                    {state.isDone ? 'Close' : 'Save'}
                </Button>
            </Grid>
        </Grid>
    )
}

export default EditDeploymentSettings
