import { FunctionComponent, useContext, useState } from 'react'
import { FlowInstanceModel } from '../../Model/AllSparkModel'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import styles from './../../deployments/edit/Edit.module.scss'
import Input from '../../../infratructure/input/Input'
import cx from 'classnames'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import { AllSparkContext } from '../../../..'
import { useGlobalStateContext } from '../../../../GlobalState'
import { Util } from '../../../utils/Util'
import { Logger } from '../../../utils/Logger'

interface EditFlowAuthorizationDetailsProps {
    instance?: FlowInstanceModel
    onCancel: () => void
    onClose: () => void
    onUpdate: (isWorking: boolean) => void
}

type EditFlowAuthorizationState = {
    override: boolean
    hasCustomOverrides: boolean
    isWorking: boolean
    isDone: boolean
    hasErrors: boolean
    externalTenantId: string
    externalClientId: string
    externalClientSecret: string
    externalSecretExpiry: string
    canInvite: boolean
}

const EditFlowAuthorizationDetails: FunctionComponent<EditFlowAuthorizationDetailsProps> = ({
    instance,
    onCancel,
    onClose,
    onUpdate,
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditFlowAuthorizationState>({
        override: instance?.flowAuthorization?.externalAuthorizationModel !== null,
        hasCustomOverrides: instance?.flowAuthorization?.externalAuthorizationModel !== null,
        isWorking: false,
        isDone: false,
        hasErrors: false,
        externalTenantId: instance?.flowAuthorization?.externalAuthorizationModel?.tenantId || '',
        externalClientId: instance?.flowAuthorization?.externalAuthorizationModel?.clientId || '',
        externalClientSecret: instance?.flowAuthorization?.externalAuthorizationModel?.clientSecret || '',
        externalSecretExpiry: '',
        canInvite: true,
    })

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSave = async () => {
        onUpdate(true)
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isWorking: true, hasErrors: false }))

        try {
            const authRequest = {
                override: state.override,
                tenantId: state.externalTenantId,
                clientId: state.externalClientId,
                clientSecret: state.externalClientSecret,
                secretExpiry: state.externalSecretExpiry || Util.getDefaultExpiryDateString(),
                canInvite: state.canInvite,
            }

            await api.updateFlowAuth(instance?.deploymentId!, authRequest)

            setState((prev) => ({ ...prev, isWorking: false, isDone: true }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } catch (error) {
            Logger.logError('An error occurred:', error)
            setState((prev) => ({ ...prev, isWorking: false, hasErrors: true }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } finally {
            onUpdate(false)
        }
    }

    const onCanInviteChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setState((prev) => ({ ...prev, canInvite: !state.canInvite }))
    }

    const isButtonDisabled = state.override
        ? state.isWorking
            ? true
            : !(
                  Boolean(state.externalTenantId) &&
                  Boolean(state.externalClientId) &&
                  Boolean(state.externalClientSecret) &&
                  Boolean(state.externalSecretExpiry)
              )
        : false

    return (
        <Grid rows="1fr auto auto" className={styles.main} gap={0}>
            <Heading type="heading1">Edit Authorization</Heading>
            <Heading type="heading2" style={{ marginTop: '20px' }}>
                The application will be restarted when this config is saved.
            </Heading>
            <div className={styles.footer} style={{ marginTop: '20px', marginBottom: '30px' }}>
                <Grid gap={20}>
                    <Grid columns="auto auto auto 1fr" gap={10} style={{ alignItems: 'center' }}>
                        <Input
                            name="automatic"
                            type="radio"
                            checked={!state.override}
                            onChange={() => setState((prev) => ({ ...prev, override: !state.override }))}
                            style={{ width: '16px', margin: 0, height: '16px' }}
                            disabled={state.isWorking || state.hasCustomOverrides || state.isDone}
                        />
                        <Heading type="normal">Automatic renew (secrets)</Heading>
                        <Input
                            name="manual"
                            type="radio"
                            checked={state.override}
                            onChange={() => setState((prev) => ({ ...prev, override: !state.override }))}
                            style={{ width: '16px', margin: 0, height: '16px' }}
                            disabled={state.isWorking || state.hasCustomOverrides || state.isDone}
                        />
                        <Heading type="normal">Configure manually</Heading>
                    </Grid>
                    {instance?.flowAuthorization.externalAuthorizationModel !== null || state.override ? (
                        <Grid gap={20}>
                            <Input
                                name="externalTenantId"
                                headerText={'OpenId Connect Tenant Id'}
                                placeholder="Enter the OpenId Connect Tenant Id"
                                style={{ width: 'inherit' }}
                                value={state.externalTenantId}
                                onChange={onInputChange}
                                className={cx({
                                    [styles.requiredInput]: !Boolean(state.externalTenantId),
                                })}
                                readOnly={state.isWorking || state.isDone}
                            />
                            <Input
                                name="externalClientId"
                                headerText={'Client Id'}
                                placeholder="Enter Client Id"
                                style={{ width: 'inherit' }}
                                value={state.externalClientId}
                                onChange={onInputChange}
                                className={cx({
                                    [styles.requiredInput]: !Boolean(state.externalClientId),
                                })}
                                readOnly={state.isWorking || state.isDone}
                            />
                            <Input
                                name="externalClientSecret"
                                headerText={'Client Secret'}
                                placeholder="Enter Client Secret"
                                style={{ width: 'inherit' }}
                                value={state.externalClientSecret}
                                onChange={onInputChange}
                                className={cx({
                                    [styles.requiredInput]: !Boolean(state.externalClientSecret),
                                })}
                                readOnly={state.isWorking || state.isDone}
                            />
                            <Input
                                name="externalSecretExpiry"
                                headerText={'Client Secret Expiry'}
                                type="datetime-local"
                                style={{ width: 'inherit' }}
                                value={state.externalSecretExpiry}
                                onChange={onInputChange}
                                className={cx({
                                    [styles.requiredInput]: !Boolean(state.externalSecretExpiry),
                                })}
                                readOnly={state.isWorking || state.isDone}
                            />
                            <Grid columns="auto 1fr" gap={10} style={{ alignItems: 'center', marginBottom: '0px' }}>
                                <Input
                                    name="canInvite"
                                    type="checkbox"
                                    checked={state.canInvite}
                                    onChange={onCanInviteChanged}
                                    style={{ width: '16px', margin: 0, height: '16px' }}
                                    readOnly={state.isWorking || state.isDone}
                                />
                                <Heading type="normal">Can Invite</Heading>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid gap={20} className={styles.info}>
                            Client secret for the registered web application will be renewed, and the deployment
                            restarted.
                        </Grid>
                    )}
                    <Grid
                        columns="1fr auto auto auto auto"
                        style={{ height: '20px', marginTop: '20px', marginRight: '0px' }}
                        gap={10}
                    >
                        {state.isWorking && !state.isDone ? (
                            <Grid columns="auto auto 1fr" style={{ height: '30px' }} gap={10}>
                                <Grid style={{ alignItems: 'center' }}>Updating</Grid>
                                <Grid style={{ alignItems: 'center' }}>
                                    <LoaderDots color="black" />
                                </Grid>
                            </Grid>
                        ) : (
                            <div />
                        )}
                        {!state.isWorking && state.isDone ? (
                            <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                                <Grid style={{ alignItems: 'center' }}>Done!</Grid>
                                <div />
                            </Grid>
                        ) : (
                            <div />
                        )}
                        {state.hasErrors ? (
                            <Grid columns="auto 1fr" style={{ height: '30px' }} gap={10}>
                                <Grid style={{ alignItems: 'center' }}>Failed! Check System Log for details.</Grid>
                                <div />
                            </Grid>
                        ) : (
                            <div />
                        )}
                        {!state.isDone ? (
                            <Button
                                className={styles.deploybtn}
                                buttonType={ButtonType.Default}
                                onClick={onCancel}
                                disabled={state.isWorking}
                            >
                                Cancel
                            </Button>
                        ) : (
                            <div />
                        )}
                        <Button
                            className={styles.deploybtn}
                            buttonType={ButtonType.Confirm}
                            onClick={state.isDone ? onClose : handleSave}
                            disabled={isButtonDisabled || state.isWorking}
                        >
                            {state.isDone ? 'Close' : 'Save'}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}

export default EditFlowAuthorizationDetails
