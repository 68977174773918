import { AllSparkContext } from '../../../..'
import styles from './Edit.module.scss'
import { FunctionComponent, useContext, useState } from 'react'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import LoaderDots from '../../../infratructure/dots/LoaderDots'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import { useGlobalStateContext } from '../../../../GlobalState'
import { ProductType } from '../../Model/AllSparkModel'
import { Logger } from '../../../utils/Logger'

interface EditAzureBlobContainerDetailsProps {
    deploymentId: string | undefined
    onUpdate: (isUpdating: boolean) => void
    onClose: (success: boolean) => void
    productType: ProductType
}

type EditBlobContainerDetailsState = {
    deploymentId: string
    isDeploying: boolean
    isDone: boolean
    hasErrors: boolean
}

const EditAzureBlobContainerDetails: FunctionComponent<EditAzureBlobContainerDetailsProps> = ({
    deploymentId,
    onUpdate,
    onClose,
    productType,
}) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<EditBlobContainerDetailsState>({
        deploymentId: deploymentId || '',
        isDeploying: false,
        isDone: false,
        hasErrors: false,
    })

    const update = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isDeploying: true, hasErrors: false }))
        onUpdate(true)

        try {
            await api.updateStorageSasToken(state.deploymentId, productType)

            setState((prev) => ({ ...prev, isDeploying: false, isDone: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } catch (error: any) {
            Logger.logError(`An error occurred, updating the storage sas token failed: ${error.message}`)
            setState((prev) => ({ ...prev, isDeploying: false, hasErrors: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    return (
        <Grid rows="1fr auto auto auto" className={styles.main} gap={0}>
            <Heading type="heading1">Edit Azure BlobContainer</Heading>
            <Heading type="heading2" style={{ marginTop: '20px' }}>
                Renew SAS Uri
            </Heading>
            <div className={styles.editcontainer}>
                <Grid columns="1fr auto" gap={0}>
                    <Grid columnSpan={2} style={{ marginTop: '4px' }} />

                    {state.isDone && (
                        <Grid gap={20} className={styles.info} columnSpan={3}>
                            SASUri renewal is finished.
                        </Grid>
                    )}

                    {state.isDeploying && (
                        <Grid
                            columns="1fr auto"
                            columnSpan={2}
                            style={{ marginBottom: '20px', marginTop: '10px', marginRight: '5px' }}
                        >
                            <div />
                            <Grid columns="1fr auto" gap={10}>
                                <Grid>Updating</Grid>
                                <Grid style={{ alignItems: 'center', marginBottom: '3px' }}>
                                    <LoaderDots color="black" />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {state.hasErrors && (
                        <Grid gap={20} columnSpan={2} className={styles.info} style={{ marginBottom: '10px' }}>
                            An error occurred, update failed.
                        </Grid>
                    )}

                    {!state.isDeploying && !state.isDone && (
                        <Grid rows="auto auto" gap={20} style={{ marginBottom: '10px' }}>
                            <div>The BlobContainer SAS Uri will be renewed, and the application restarted.</div>
                            <Heading type="heading1" style={{ marginRight: '10px', marginTop: '0px' }}></Heading>
                            <Grid columns="1fr auto">
                                <div />
                                <Button className={styles.deploybtn} buttonType={ButtonType.Confirm} onClick={update}>
                                    Renew
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                    {state.isDone && (
                        <Grid columns="1fr auto" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <div />
                            <Button
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={() => onClose(!state.hasErrors)}
                            >
                                Close
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default EditAzureBlobContainerDetails
