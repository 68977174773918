import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Modal from 'react-responsive-modal'
import { AllSparkContext } from '../../..'
import { DeploymentLog, SystemLog } from '../Model/AllSparkModel'
import { useGlobalStateContext } from '../../../GlobalState'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import { ArrayUtil } from '../../utils/ArrayUtil'
import EditSystemLogs from './EditSystemLogs'
import styles from './SystemLog.module.scss'
import SystemLogDeploymentsList from './SystemLogDeploymentsList'
import SystemLogDetails from './SystemLogDetails'
import SystemLogToolbar from './SystemLogToolbar'
import { Logger } from '../../utils/Logger'

type SystemLogState = {
    deployments: string[]
    deploymentsList: DeploymentLog[]
    selectedDeploymentLogs: SystemLog[] | undefined
    isWorking: boolean
    isDeleteOpen: boolean
    isValidating: boolean
}

const SystemLogs: FunctionComponent = () => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const {
        globalState: { selectedSystemLogDeploymentId },
        setGlobalState,
    } = useGlobalStateContext()

    const [{ deployments, deploymentsList, selectedDeploymentLogs, isDeleteOpen, isWorking, isValidating }, setState] =
        useState<SystemLogState>({
            deployments: [],
            deploymentsList: [],
            selectedDeploymentLogs: undefined,
            isWorking: false,
            isDeleteOpen: false,
            isValidating: false,
        })

    const onGetData = useCallback(async () => {
        refreshDeployments()
    }, [api, setGlobalState, throwError])

    const getLogs = async (deploymentId: string) => {
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            const logs = await api.getDeploymentSystemLogs(deploymentId)
            if (logs && logs?.length > 0) {
                setState((prev) => ({
                    ...prev,
                    selectedDeploymentLogs: logs.sort(ArrayUtil.sortByPropCompare('timestamp')).reverse(),
                }))
            } else {
                setState((prev) => ({ ...prev, selectedDeploymentLogs: undefined }))
            }
        } catch (error) {
            Logger.logError('Exception : Unable to load data. Check if the backend is running !!')
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        } finally {
            setState((prev) => ({ ...prev, isWorking: false }))
        }
    }

    // load data on mount
    useEffect(() => {
        onGetData()
    }, [api, onGetData])

    const closeIcon = () => (isWorking || isValidating ? <div /> : '')

    const onSelectedDeploymentChange = async (deployment: DeploymentLog) => {
        setGlobalState((prev) => ({ ...prev, selectedSystemLogDeploymentId: deployment.deploymentId }))
        await getLogs(deployment.deploymentId)
    }

    const selectedDeployment = useMemo(
        () => deploymentsList.find((d) => d.deploymentId === selectedSystemLogDeploymentId),
        [deployments, selectedSystemLogDeploymentId],
    )

    const onDeleteDeploymentSystemLogs = () => {
        setState((prev) => ({ ...prev, isDeleteOpen: true }))
    }

    const onDeleteDeploymentSystemLogsClose = (change: boolean, success: boolean) => {
        setState((prev) => ({ ...prev, isDeleteOpen: false }))
        if (change && success) {
            refreshDeployments()
        }
    }

    const refreshDeployments = async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        setGlobalState((prev) => ({ ...prev, selectedSystemLogDeploymentId: undefined }))
        try {
            const deployments = await api.getSystemLogsDeployments()
            if (deployments) {
                const dpl: DeploymentLog[] = []
                dpl.push({ deploymentId: '____general____', name: '[Logs not connected to deployment]' })
                for (var i = 0, len = deployments.length; i < len; ++i) {
                    dpl.push({ deploymentId: deployments[i], name: deployments[i] })
                }
                setState((prev) => ({ ...prev, deployments: deployments, deploymentsList: dpl }))
            }
        } catch (error) {
            Logger.logError('Exception : Unable to load data. Check if the backend is running !!')
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        } finally {
            setState((prev) => ({ ...prev, isWorking: false }))
        }
    }

    return (
        <Grid rows="auto 1fr" style={{ overflow: 'hidden' }}>
            <SystemLogToolbar
                hasItemSelected={!!selectedSystemLogDeploymentId && selectedDeploymentLogs !== undefined}
                isWorking={false}
                onRefresh={onGetData}
                onDelete={onDeleteDeploymentSystemLogs}
            />
            <Grid rows="auto 1fr" columns="350px 1fr" gap={4} className={styles.container}>
                <Grid>
                    <Heading type="heading1">Deployment logs</Heading>
                </Grid>
                <Grid>{selectedDeployment !== undefined && <Heading type="heading1">Log items</Heading>}</Grid>
                <SystemLogDeploymentsList
                    items={deploymentsList}
                    onSelectedChange={onSelectedDeploymentChange}
                    selectedItems={selectedDeployment ? [selectedDeployment] : undefined}
                />
                {selectedDeployment !== undefined && (
                    <SystemLogDetails items={selectedDeploymentLogs || []} selected={undefined} />
                )}
            </Grid>

            {isDeleteOpen && (
                <Modal
                    open={isDeleteOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isDeleteOpen: false,
                            }))
                        }
                    }}
                >
                    <div />
                    <EditSystemLogs
                        deploymentId={selectedSystemLogDeploymentId || ''}
                        onClose={(change, success) => {
                            onDeleteDeploymentSystemLogsClose(change, success)
                        }}
                    />
                </Modal>
            )}
        </Grid>
    )
}

export default SystemLogs
