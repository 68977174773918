import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import styles from './Flows.module.scss'
import cx from 'classnames'
import Grid from '../../infratructure/grid/Grid'
import FlowsToolbar from './FlowsToolbar'
import { AllSparkContext } from '../../..'
import useAsyncError from '../../infratructure/hooks/useAsyncError'
import { useGlobalStateContext } from '../../../GlobalState'
import { EnvironmentModel, FlowInstanceModel, ProductType, Toggle, VersionResponse } from '../Model/AllSparkModel'
import Modal from 'react-responsive-modal'
import NewFlow from './new/NewFlow'
import { ArrayUtil } from '../../utils/ArrayUtil'
import Heading from '../../infratructure/heading/Heading'
import FlowInstanceList from './FlowInstanceList'
import ButtonNavigator from '../../infratructure/buttonNavigator/ButtonNavigator'
import DeleteDeploymentConfirmation from '../deployments/delete/DeleteDeploymentConfigrmation'
import UpgradeDeployment from '../deployments/upgrade/UpgradeDeployment'
import FlowInstanceDetails from './details/FlowInstanceDetails'
import EditFlowScalingDetails from './edit/EditFlowScalingDetails'
import EditSettings from './edit/EditSettings'
import EditFlowAuthorizationDetails from './edit/EditFlowAuthorizationDetails'
import EditAzureSqlDetails from '../deployments/edit/EditAzureSqlDetails'
import EditAzureServiceBus from '../deployments/edit/EditAzureServiceBus'
import Confirmation from '../shared/Confirmation'
import EditFlowEncryptionDetails from './edit/EditFlowEncryptionDetails'
import ConfirmDialog from '../shared/ConfirmDialog'
import Select from '../../infratructure/select/Select'
import CloneFlowDeployment from './clone/CloneFlowDeployment'
import { Logger } from '../../utils/Logger'

export enum FlowDetailsPanels {
    Overview,
    Authorization,
    Servicebus,
    Database,
    Scaling,
    Encryption,
}

type FlowsState = {
    isWorking: boolean
    isNewOpen: boolean
    isDeleteDialogVisible: boolean
    isUpgradeDialogVisible: boolean
    isValidating: boolean
    hasError: boolean
    versions: VersionResponse | undefined
    isEditScalingOpen: boolean
    isFlowSettingsDialogVisible: boolean
    isAzureAuthorizationEditOpen: boolean
    isAzureSqlEditOpen: boolean
    isAzureServiceBusEditOpen: boolean
    isEncryptionEditOpen: boolean
    isToggleInstanceDialogVisible: boolean
    currentToggle: Toggle
    isInUse: boolean
    isRebootConfirmDialogOpen: boolean
    completedMessage: string
    isFinished: boolean
    selectedEnvironmentFilter: string
    filterText: string
    allDeployments: FlowInstanceModel[]
    environments: EnvironmentModel[]
    isCloneDialogVisible: boolean
}

const Flows: FunctionComponent = () => {
    const api = useContext(AllSparkContext)
    const throwError = useAsyncError()
    const {
        globalState: { flows, deployments, selectedFlowDeploymentId, clusterControllers: clusterControllers },
        setGlobalState,
    } = useGlobalStateContext()

    const selectedFlowInstance = useMemo(
        () => flows.find((dep) => dep.deploymentId === selectedFlowDeploymentId),
        [flows, selectedFlowDeploymentId],
    )

    const [
        {
            isWorking,
            isNewOpen,
            isDeleteDialogVisible,
            isUpgradeDialogVisible,
            isValidating,
            hasError,
            versions,
            isEditScalingOpen,
            isFlowSettingsDialogVisible,
            isAzureAuthorizationEditOpen,
            isAzureSqlEditOpen,
            isAzureServiceBusEditOpen,
            isEncryptionEditOpen,
            isToggleInstanceDialogVisible,
            currentToggle,
            isInUse,
            isRebootConfirmDialogOpen,
            completedMessage,
            isFinished,
            selectedEnvironmentFilter,
            filterText,
            allDeployments,
            environments,
            isCloneDialogVisible,
        },
        setState,
    ] = useState<FlowsState>({
        isWorking: false,
        isNewOpen: false,
        isDeleteDialogVisible: false,
        isUpgradeDialogVisible: false,
        isValidating: false,
        hasError: false,
        versions: undefined,
        isEditScalingOpen: false,
        isFlowSettingsDialogVisible: false,
        isAzureAuthorizationEditOpen: false,
        isAzureSqlEditOpen: false,
        isAzureServiceBusEditOpen: false,
        isEncryptionEditOpen: false,
        isToggleInstanceDialogVisible: false,
        currentToggle: selectedFlowInstance?.enabled === false ? Toggle.Enable : Toggle.Disable,
        isInUse: false,
        isRebootConfirmDialogOpen: false,
        completedMessage: '',
        isFinished: false,
        selectedEnvironmentFilter: 'All',
        filterText: '',
        allDeployments: [],
        environments: [],
        isCloneDialogVisible: false,
    })

    const prevDepsRef = useRef({ filterText })

    const closeIcon = () => (isWorking || isValidating ? <div /> : '')
    const errorMessageModalStyle: React.CSSProperties = {
        height: hasError ? '650px' : 'inherit',
    }

    const onGetData = useCallback(async () => {
        setState((prev) => ({ ...prev, isWorking: true }))
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: false }))

        try {
            const allversions = await api.getVersions(ProductType.Flow)
            if (allversions) {
                setState((prev) => ({ ...prev, versions: allversions }))
            }
        } catch (error: any) {
            Logger.logError(error.Message)
        }

        try {
            let clusterControllers = await api.getClusterControllers()
            if (!clusterControllers) {
                clusterControllers = []
            }

            const environments = await api.getEnvironments()
            const deployments = await api.getDeployments()
            const flows = await api.getFlowDeployments()
            if (flows) {
                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    environments: environments?.sort(ArrayUtil.sortByPropCompare('name', true)) || [],
                    allDeployments: flows,
                }))

                let dpl: FlowInstanceModel[]
                if (selectedEnvironmentFilter !== 'All') {
                    dpl = flows
                        .filter((d) => d.environmentRowKey === selectedEnvironmentFilter)
                        .sort(ArrayUtil.sortByPropCompare('name', true))
                } else {
                    dpl = flows.sort(ArrayUtil.sortByPropCompare('name', true))
                }

                setGlobalState((prev) => ({
                    ...prev,
                    flows: dpl,
                    deployments: deployments.sort(ArrayUtil.sortByPropCompare('name', true)),
                    clusterControllers: clusterControllers,
                }))

                if (!flows.some((p) => p.deploymentId === selectedFlowDeploymentId)) {
                    setGlobalState((prev) => ({
                        ...prev,
                        selectedFlowDeploymentId: flows.length > 0 ? flows[0].deploymentId : undefined,
                    }))
                }
            } else {
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        } catch (error) {
            Logger.logError(
                'Exception : Unable to load data. Press refresh in the browser or check if the backend is running !!',
                error,
            )
            setState((prev) => ({ ...prev, isWorking: false }))
            throwError(error)
        } finally {
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }, [api, selectedFlowDeploymentId, setGlobalState, throwError])

    // load data on mount
    useEffect(() => {
        if (prevDepsRef.current.filterText === filterText) {
            onGetData()
        }
    }, [onGetData])

    const handleDeploymentSuccess = (flow: FlowInstanceModel) => {
        setState((prev) => ({
            ...prev,
            isNewOpen: false,
        }))

        setGlobalState((prev) => ({
            ...prev,
            flows: [flow, ...flows].sort(ArrayUtil.sortByPropCompare('name', true)),
            selectedFlowDeploymentId: flow.deploymentId,
        }))
    }

    const handleCloneSuccess = (flow: FlowInstanceModel) => {
        setState((prev) => ({
            ...prev,
            isCloneDialogVisible: false,
        }))

        setGlobalState((prev) => ({
            ...prev,
            flows: [flow, ...flows].sort(ArrayUtil.sortByPropCompare('name', true)),
            selectedFlowDeploymentId: flow.deploymentId,
        }))
    }

    const onSelectedDeploymentChange = (flow: FlowInstanceModel) => {
        setGlobalState((prev) => ({ ...prev, selectedFlowDeploymentId: flow.deploymentId }))
    }

    const [selectedDetailsSection, setSelectedDetailsSection] = useState<FlowDetailsPanels>(FlowDetailsPanels.Overview)

    const onDetailsNavButtonClicked = (id: string) => {
        setSelectedDetailsSection(FlowDetailsPanels[id as keyof typeof FlowDetailsPanels])
    }

    const onDeleteDialogClose = async () => {
        setState((prev) => ({ ...prev, isDeleteDialogVisible: false }))
        await refreshFlows()
    }

    async function refreshFlows() {
        let selected = selectedFlowInstance?.deploymentId
        let newSelected: string | undefined

        const deployments = await api.getDeployments()
        const flows = await api.getFlowDeployments()

        if (flows.some((d) => d.deploymentId === selected)) {
            newSelected = selected
        } else {
            newSelected = flows.length > 0 ? flows[0].deploymentId : undefined
        }

        setGlobalState((prev) => ({
            ...prev,
            flows: flows.sort(ArrayUtil.sortByPropCompare('name', true)),
            deployments: deployments.sort(ArrayUtil.sortByPropCompare('name', true)),
            selectedFlowDeploymentId: newSelected,
        }))
    }

    const onDeploymentUpgraded = async (newVersion: string) => {
        let dep: FlowInstanceModel | undefined = flows.find((d) => d.deploymentId === selectedFlowDeploymentId)

        if (dep && newVersion) {
            dep = {
                ...dep,
                platformVersion: newVersion,
            }

            setGlobalState((prev) => ({
                ...prev,
                flows: flows.map((o) => (o.deploymentId === dep?.deploymentId ? dep : o)),
                selectedFlowDeploymentId: dep?.deploymentId,
            }))
        }

        await refreshFlows()
    }

    const onScalingEdit = () => {
        setState((prev) => ({ ...prev, isEditScalingOpen: true }))
    }

    const onEditScalingCancel = () => {
        setState((prev) => ({ ...prev, isEditScalingOpen: false }))
    }

    const onEditScalingClose = async () => {
        setState((prev) => ({ ...prev, isWorking: false, isEditScalingOpen: false }))
        await refreshFlows()
    }

    const onEditSettingsClose = () => {
        setState((prev) => ({ ...prev, isWorking: false, isFlowSettingsDialogVisible: false }))
    }

    const onAzureAuthorizationEdit = () => {
        setState((prev) => ({ ...prev, isAzureAuthorizationEditOpen: true }))
    }

    const onUpdateAuthorizationClose = async () => {
        setState((prev) => ({ ...prev, isAzureAuthorizationEditOpen: false }))
        await refreshFlows()
    }

    const onUpdateAuthorizationCancel = () => {
        setState((prev) => ({ ...prev, isAzureAuthorizationEditOpen: false }))
    }

    const onAzureSqlEdit = () => {
        setState((prev) => ({ ...prev, isAzureSqlEditOpen: true }))
    }

    const onUpdateSqlClose = async (success: boolean) => {
        setState((prev) => ({ ...prev, isAzureSqlEditOpen: false }))
        await refreshFlows()
    }

    const onAzureServiceBusEdit = () => {
        setState((prev) => ({ ...prev, isAzureServiceBusEditOpen: true }))
    }

    const onSetAuthoriaztionRuleClose = async (change: boolean, success: boolean) => {
        setState((prev) => ({ ...prev, isAzureServiceBusEditOpen: false }))
        if (change) {
            await refreshFlows()
        }
    }

    const onSetEncryptionClose = async (change: boolean, success: boolean) => {
        setState((prev) => ({ ...prev, isEncryptionEditOpen: false }))
        if (change) {
            await refreshFlows()
        }
    }

    const onEncryptionEdit = () => {
        setState((prev) => ({ ...prev, isEncryptionEditOpen: true }))
    }

    const onEditEncryptionCancel = () => {
        setState((prev) => ({ ...prev, isEncryptionEditOpen: false }))
    }

    const onInstanceToggle = (toogle: Toggle) => {
        let inUse = deployments.some(
            (d) => d.flowInstance?.deploymentId === selectedFlowDeploymentId && d.enabled !== false,
        )
        setState((prev) => ({
            ...prev,
            isWorking: false,
            isToggleInstanceDialogVisible: true,
            currentToggle: toogle,
            isInUse: inUse,
        }))
    }

    const onInstanceToggleClose = async () => {
        setState((prev) => ({ ...prev, isWorking: false, isToggleInstanceDialogVisible: false }))
        await refreshFlows()
    }

    const onInstanceToggleCancel = () => {
        setState((prev) => ({ ...prev, isWorking: false, isToggleInstanceDialogVisible: false }))
    }

    const onReboot = () => {
        setState((prev) => ({ ...prev, isRebootConfirmDialogOpen: true }))
    }

    const onConfirmRebootClose = () => {
        setState((prev) => ({ ...prev, isRebootConfirmDialogOpen: false, isFinished: false }))
    }

    const onConfirmRebootCancel = () => {
        setState((prev) => ({ ...prev, isRebootConfirmDialogOpen: false }))
    }

    const onConfirmReboot = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isWorking: true }))
        try {
            if (selectedFlowDeploymentId) {
                var response = await api.rebootFlowDeployment(selectedFlowDeploymentId)
                if (!response.success) {
                    Logger.logError(response.errorMessage, response.messages)
                }

                let completedMessage = response.success
                    ? `Deployment '` + selectedFlowInstance?.name + `' rebooted`
                    : `Rebooting deployment '` + selectedFlowInstance?.name + `' failed. Check console for errors.`

                setState((prev) => ({
                    ...prev,
                    isWorking: false,
                    isFinished: true,
                    completedMessage: completedMessage,
                    selectedLanguageCode: undefined,
                }))
            }

            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false, selectedLanguage: undefined }))
        } catch (error: any) {
            Logger.logError('An error occurred:', error.message)
            setState((prev) => ({
                ...prev,
                isWorking: false,
                isFinished: true,
                completedMessage:
                    `Rebooting deployment '` + selectedFlowInstance?.name + `' failed. Check console for errors.`,
            }))
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const onFilterChanged = (filter: { label: string; value: string }) => {
        let filteredDeployments: FlowInstanceModel[]
        if (filter.value === 'All') {
            filteredDeployments = allDeployments.sort(ArrayUtil.sortByPropCompare('name', true))
        } else {
            filteredDeployments = allDeployments
                .filter((d) => d.environmentRowKey === filter.value)
                .sort(ArrayUtil.sortByPropCompare('name', true))
        }

        setGlobalState((prev) => ({ ...prev, flows: filteredDeployments }))
        setState((prev) => ({ ...prev, selectedEnvironmentFilter: filter.value, filterText: '' }))
    }

    const onFilterTextChanged = (searchText: string) => {
        const filterText: string = searchText

        let filteredDeployments: FlowInstanceModel[]

        if (selectedEnvironmentFilter === 'All') {
            filteredDeployments = allDeployments
                .filter((d) => d.name.toLowerCase().includes(filterText))
                .sort(ArrayUtil.sortByPropCompare('name', true))
        } else {
            filteredDeployments = allDeployments
                .filter(
                    (d) =>
                        d.name.toLowerCase().includes(filterText) && d.environmentRowKey === selectedEnvironmentFilter,
                )
                .sort(ArrayUtil.sortByPropCompare('name', true))
        }

        setGlobalState((prev) => ({ ...prev, flows: filteredDeployments }))
        setState((prev) => ({ ...prev, filterText: filterText }))
    }

    function getFilterOptions() {
        return [{ label: 'All', value: 'All' }, ...environments.map((e) => ({ label: e.name, value: e.rowKey || '' }))]
    }

    return (
        <Grid rows="auto 1fr" style={{ overflow: 'hidden' }}>
            <FlowsToolbar
                isEnabled={selectedFlowInstance?.enabled === false ? false : true}
                hasItemSelected={!!selectedFlowDeploymentId}
                isWorking={false}
                usedByDeployments={deployments.some((d) => d.flowInstance?.deploymentId === selectedFlowDeploymentId)}
                onAdd={() => setState((prev) => ({ ...prev, isNewOpen: true }))}
                onClone={() => setState((prev) => ({ ...prev, isCloneDialogVisible: true }))}
                onUpgrade={() => setState((prev) => ({ ...prev, isUpgradeDialogVisible: true }))}
                onDelete={() => setState((prev) => ({ ...prev, isDeleteDialogVisible: true }))}
                onSettings={() => setState((prev) => ({ ...prev, isFlowSettingsDialogVisible: true }))}
                onToggle={onInstanceToggle}
                onReboot={onReboot}
            />
            <Grid rows="auto 1fr" columns="350px 1fr" gap={4} className={styles.container}>
                <Grid columns="1fr 180px">
                    <Heading type={'heading1'}>Flow Instances</Heading>
                    <Select
                        isSearchable={false}
                        isClearable={false}
                        options={getFilterOptions()}
                        value={selectedEnvironmentFilter}
                        onChange={onFilterChanged}
                        placeholder={'Filter'}
                        isDisabled={false}
                    />
                </Grid>
                {selectedFlowInstance?.enabled === false ? (
                    <div style={{ height: '32px' }} />
                ) : (
                    <ButtonNavigator
                        spacing="compact"
                        buttons={[
                            {
                                id: FlowDetailsPanels[FlowDetailsPanels.Overview],
                                caption: FlowDetailsPanels[FlowDetailsPanels.Overview],
                            },
                            {
                                id: FlowDetailsPanels[FlowDetailsPanels.Scaling],
                                caption: FlowDetailsPanels[FlowDetailsPanels.Scaling],
                            },
                            {
                                id: FlowDetailsPanels[FlowDetailsPanels.Authorization],
                                caption: FlowDetailsPanels[FlowDetailsPanels.Authorization],
                            },
                            {
                                id: FlowDetailsPanels[FlowDetailsPanels.Servicebus],
                                caption: FlowDetailsPanels[FlowDetailsPanels.Servicebus],
                            },
                            {
                                id: FlowDetailsPanels[FlowDetailsPanels.Database],
                                caption: FlowDetailsPanels[FlowDetailsPanels.Database],
                            },
                            {
                                id: FlowDetailsPanels[FlowDetailsPanels.Encryption],
                                caption: FlowDetailsPanels[FlowDetailsPanels.Encryption],
                            },
                        ]}
                        selectedButtonId={FlowDetailsPanels[selectedDetailsSection]}
                        onButtonClicked={onDetailsNavButtonClicked}
                    />
                )}

                <FlowInstanceList
                    items={flows}
                    onSelectedChange={onSelectedDeploymentChange}
                    onFilterTextChanged={onFilterTextChanged}
                    filterText={filterText}
                    selectedItems={selectedFlowInstance ? [selectedFlowInstance] : undefined}
                    versions={versions}
                />
                <FlowInstanceDetails
                    environments={environments}
                    instance={selectedFlowInstance}
                    deploymentsInUse={deployments.filter(
                        (d) => d.flowInstance?.deploymentId === selectedFlowInstance?.deploymentId,
                    )}
                    selectedDetailsSection={selectedDetailsSection}
                    onScalingEdit={onScalingEdit}
                    onAzureAuthorizationEdit={onAzureAuthorizationEdit}
                    onAzureSqlEdit={onAzureSqlEdit}
                    onAzureServiceBusEdit={onAzureServiceBusEdit}
                    onEncryptionEdit={onEncryptionEdit}
                />
            </Grid>

            {isRebootConfirmDialogOpen && (
                <Modal
                    open={isRebootConfirmDialogOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isRebootConfirmDialogOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <ConfirmDialog
                        height={160}
                        width={540}
                        message={"Are you sure you want to reboot instance '" + selectedFlowInstance?.name + "'?"}
                        workingMessage="Rebooting instance"
                        completedMessage={completedMessage}
                        isWorking={isWorking}
                        isFinished={isFinished}
                        onClose={onConfirmRebootClose}
                        onCancel={onConfirmRebootCancel}
                        onConfirm={onConfirmReboot}
                    />
                </Modal>
            )}

            {isToggleInstanceDialogVisible && (
                <Modal
                    open={isToggleInstanceDialogVisible}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isToggleInstanceDialogVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <Confirmation
                        toggle={currentToggle}
                        deploymentId={selectedFlowDeploymentId || ''}
                        deploymentName={selectedFlowInstance?.name || ''}
                        productType={ProductType.Flow}
                        onClose={onInstanceToggleClose}
                        onCancel={onInstanceToggleCancel}
                        isInUse={isInUse}
                    />
                </Modal>
            )}

            {isEncryptionEditOpen && (
                <Modal
                    open={isEncryptionEditOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isEncryptionEditOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditFlowEncryptionDetails
                        instance={selectedFlowInstance}
                        onCancel={onEditEncryptionCancel}
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(change, success) => {
                            onSetEncryptionClose(change, success)
                        }}
                    />
                </Modal>
            )}

            {isAzureServiceBusEditOpen && (
                <Modal
                    open={isAzureServiceBusEditOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isAzureServiceBusEditOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditAzureServiceBus
                        deploymentId={selectedFlowInstance?.deploymentId}
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(change, success) => {
                            onSetAuthoriaztionRuleClose(change, success)
                        }}
                        productType={ProductType.Flow}
                    />
                </Modal>
            )}

            {isAzureSqlEditOpen && (
                <Modal
                    open={isAzureSqlEditOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isAzureSqlEditOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditAzureSqlDetails
                        deploymentId={selectedFlowInstance?.deploymentId}
                        onUpdate={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        onClose={(success) => {
                            onUpdateSqlClose(success)
                        }}
                        productType={ProductType.Flow}
                    />
                </Modal>
            )}

            {isAzureAuthorizationEditOpen && (
                <Modal
                    open={isAzureAuthorizationEditOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isAzureAuthorizationEditOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditFlowAuthorizationDetails
                        instance={selectedFlowInstance}
                        onCancel={onUpdateAuthorizationCancel}
                        onClose={onUpdateAuthorizationClose}
                        onUpdate={(w) => setState((prev) => ({ ...prev, isWorking: w }))}
                    />
                </Modal>
            )}

            {isFlowSettingsDialogVisible && (
                <Modal
                    open={isFlowSettingsDialogVisible}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isFlowSettingsDialogVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditSettings
                        onCancel={onEditSettingsClose}
                        isWorking={isWorking}
                        onSave={() => setState((prev) => ({ ...prev, isWorking: true }))}
                        onClose={onEditSettingsClose}
                    />
                </Modal>
            )}

            {isEditScalingOpen && (
                <Modal
                    open={isEditScalingOpen}
                    closeIcon={closeIcon()}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isEditScalingOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                >
                    <EditFlowScalingDetails
                        deployment={selectedFlowInstance}
                        onCancel={onEditScalingCancel}
                        isWorking={isWorking}
                        onSave={() => setState((prev) => ({ ...prev, isWorking: true }))}
                        onClose={onEditScalingClose}
                    />
                </Modal>
            )}

            {isNewOpen && (
                <Modal
                    open={isNewOpen}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking && !isValidating) {
                            setState((prev) => ({
                                ...prev,
                                isNewOpen: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal, errorMessageModalStyle),
                    }}
                    closeIcon={closeIcon()}
                >
                    <NewFlow
                        onValidate={(v) => {
                            setState((prev) => ({ ...prev, isValidating: v }))
                        }}
                        onDeploy={(v) => {
                            setState((prev) => ({ ...prev, isWorking: v }))
                        }}
                        closeDialog={handleDeploymentSuccess}
                    />
                </Modal>
            )}

            {isDeleteDialogVisible && (
                <Modal
                    open={isDeleteDialogVisible}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isDeleteDialogVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal),
                    }}
                    closeIcon={closeIcon()}
                >
                    <DeleteDeploymentConfirmation
                        selectedDeployment={selectedFlowInstance}
                        productType={ProductType.Flow}
                        onClose={onDeleteDialogClose}
                        onWorking={(w) => setState((prev) => ({ ...prev, isWorking: w }))}
                        refresh={refreshFlows}
                    />
                </Modal>
            )}

            {isUpgradeDialogVisible && (
                <Modal
                    open={isUpgradeDialogVisible}
                    closeOnOverlayClick={false}
                    onClose={() => setState((prev) => ({ ...prev, isUpgradeDialogVisible: false }))}
                    classNames={{
                        modal: styles.customUpgradeModal,
                    }}
                    closeIcon={closeIcon()}
                >
                    <UpgradeDeployment
                        deploymentId={selectedFlowInstance?.deploymentId}
                        deploymentName={selectedFlowInstance?.name}
                        currentPlatformVersion={selectedFlowInstance?.platformVersion}
                        clusterControllers={clusterControllers || []}
                        currentClusterController={selectedFlowInstance?.clusterController?.name || 'AzureGateway'}
                        onDeploymentUpgraded={onDeploymentUpgraded}
                        onUpgrading={() => setState((prev) => ({ ...prev, isWorking: true }))}
                        onUpgradeFinished={() => setState((prev) => ({ ...prev, isWorking: false }))}
                        onClose={() =>
                            setState((prev) => ({
                                ...prev,
                                isUpgradeDialogVisible: false,
                            }))
                        }
                        productType={ProductType.Flow}
                    />
                </Modal>
            )}

            {isCloneDialogVisible && (
                <Modal
                    open={isCloneDialogVisible}
                    closeOnOverlayClick={false}
                    onClose={() => {
                        if (!isWorking) {
                            setState((prev) => ({
                                ...prev,
                                isCloneDialogVisible: false,
                            }))
                        }
                    }}
                    classNames={{
                        modal: cx(styles.customModal),
                    }}
                    closeIcon={closeIcon()}
                >
                    <CloneFlowDeployment
                        deploymentName={selectedFlowInstance?.name || ''}
                        deploymentId={selectedFlowInstance?.deploymentId || ''}
                        closeDialog={handleCloneSuccess}
                        onDeploy={(deploy) => {
                            setState((prev) => ({ ...prev, isWorking: deploy }))
                        }}
                    />
                </Modal>
            )}
        </Grid>
    )
}

export default Flows
