import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import LoaderDots from '../../infratructure/dots/LoaderDots'
import { AllSparkContext } from '../../..'
import { useGlobalStateContext } from '../../../GlobalState'
import styles from './NewClusterController.module.scss'
import cx from 'classnames'
import Input from '../../infratructure/input/Input'
import Button, { ButtonType } from '../../infratructure/button/Button'
import { Logger } from '../../utils/Logger'

type NewClusterControllerProps = {
    onUpdate: (isUpdating: boolean) => void
    onClose: (change: boolean, success: boolean) => void
}

type NewClusterControllerState = {
    isDeploying: boolean
    isDone: boolean
    hasErrors: boolean
    clusterControllerName: string
    clusterControllerInvisionChart: string
    clusterControllerFlowChart: string
}

const NewClusterController: FunctionComponent<NewClusterControllerProps> = ({ onUpdate, onClose }) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<NewClusterControllerState>({
        isDeploying: false,
        isDone: false,
        hasErrors: false,
        clusterControllerName: '',
        clusterControllerInvisionChart: '',
        clusterControllerFlowChart: '',
    })

    const addClusterController = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isDeploying: true, hasErrors: false }))
        onUpdate(true)

        try {
            await api.addClusterController({
                name: state.clusterControllerName,
                invisionChart: state.clusterControllerInvisionChart,
                flowChart: state.clusterControllerFlowChart,
            })

            setState((prev) => ({ ...prev, isDeploying: false, isDone: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } catch (error: any) {
            Logger.logError(`An error occurred, when adding Cluster Controller: ${error.message}`)
            setState((prev) => ({ ...prev, isDeploying: false, hasErrors: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }

    const isClusterControllerValid =
        !Boolean(state.clusterControllerName) ||
        !Boolean(state.clusterControllerInvisionChart) ||
        !Boolean(state.clusterControllerFlowChart)

    return (
        <Grid rows="auto 1fr auto" className={styles.main} gap={0}>
            <Heading type="heading1">Add Cluster Controller</Heading>
            <div className={styles.footer}>
                <Grid columns="1fr auto auto" gap={0}>
                    <div></div>

                    {state.isDone && (
                        <Grid gap={0} className={styles.info} columnSpan={3}>
                            Cluster Controller added
                        </Grid>
                    )}

                    {state.isDeploying && (
                        <Grid columns="1fr auto" columnSpan={2} style={{ marginBottom: '20px', marginTop: '20px' }}>
                            <div />
                            <Grid columns="1fr auto" gap={10}>
                                <Grid>Updating</Grid>
                                <Grid style={{ alignItems: 'center', marginBottom: '3px' }}>
                                    <LoaderDots color="black" />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {state.hasErrors && (
                        <Grid gap={20} className={styles.info}>
                            An error occurred, update failed
                        </Grid>
                    )}

                    {!state.isDeploying && !state.isDone && (
                        <Grid rows="auto auto" columns="1fr auto" columnSpan={3} gap={20}>
                            <Grid columnSpan={2}>
                                <Input
                                    name="clusterControllerName"
                                    headerText={'Name'}
                                    placeholder="Name"
                                    style={{ width: 'inherit' }}
                                    value={state.clusterControllerName}
                                    onChange={(e) =>
                                        setState((prev) => ({
                                            ...prev,
                                            clusterControllerName: e.target.value,
                                        }))
                                    }
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.clusterControllerName),
                                    })}
                                    readOnly={state.isDeploying}
                                />
                            </Grid>
                            <Grid columnSpan={2}>
                                <Input
                                    name="clusterControllerInvisionChart"
                                    headerText={'Invision HELM Chart'}
                                    placeholder="E.g. helm/invision-aks-asb"
                                    style={{ width: 'inherit' }}
                                    value={state.clusterControllerInvisionChart}
                                    onChange={(e) =>
                                        setState((prev) => ({
                                            ...prev,
                                            clusterControllerInvisionChart: e.target.value,
                                        }))
                                    }
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.clusterControllerInvisionChart),
                                    })}
                                    readOnly={state.isDeploying}
                                />
                            </Grid>
                            <Grid columnSpan={2}>
                                <Input
                                    name="clusterControllerFlowChart"
                                    headerText={'Flow HELM Chart'}
                                    placeholder="E.g. helm/flow"
                                    style={{ width: 'inherit' }}
                                    value={state.clusterControllerFlowChart}
                                    onChange={(e) =>
                                        setState((prev) => ({
                                            ...prev,
                                            clusterControllerFlowChart: e.target.value,
                                        }))
                                    }
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.clusterControllerFlowChart),
                                    })}
                                    readOnly={state.isDeploying}
                                />
                            </Grid>
                            <div />
                            <Button
                                style={{ marginBottom: '10px' }}
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={addClusterController}
                                disabled={isClusterControllerValid}
                            >
                                Save
                            </Button>
                        </Grid>
                    )}

                    {state.isDone && (
                        <Grid columns="1fr auto" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <div />
                            <Button
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={() => onClose(true, !state.hasErrors)}
                            >
                                Close
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default NewClusterController
