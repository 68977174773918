import { FunctionComponent, useContext, useState } from 'react'
import Grid from '../../infratructure/grid/Grid'
import Heading from '../../infratructure/heading/Heading'
import LoaderDots from '../../infratructure/dots/LoaderDots'
import { AllSparkContext } from '../../..'
import { useGlobalStateContext } from '../../../GlobalState'
import styles from './NewLanguage.module.scss'
import Input from '../../infratructure/input/Input'
import cx from 'classnames'
import Button, { ButtonType } from '../../infratructure/button/Button'
import { Logger } from '../../utils/Logger'

interface NewLanguageProps {
    onUpdate: (isUpdating: boolean) => void
    onClose: (change: boolean, success: boolean) => void
}

type NewLanguageState = {
    isDeploying: boolean
    isDone: boolean
    hasErrors: boolean
    language: string
}

const NewLanguage: FunctionComponent<NewLanguageProps> = ({ onUpdate, onClose }) => {
    const api = useContext(AllSparkContext)
    const { setGlobalState } = useGlobalStateContext()

    const [state, setState] = useState<NewLanguageState>({
        isDeploying: false,
        isDone: false,
        hasErrors: false,
        language: '',
    })

    const addLanguage = async () => {
        setGlobalState((prev) => ({ ...prev, isSpinning: true, isSparking: true }))
        setState((prev) => ({ ...prev, isDeploying: true, hasErrors: false }))
        onUpdate(true)

        try {
            await api.addLanguage(state.language)

            setState((prev) => ({ ...prev, isDeploying: false, isDone: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        } catch (error: any) {
            Logger.logError(`An error occurred, when adding language: ${error.message}`)
            setState((prev) => ({ ...prev, isDeploying: false, hasErrors: true }))
            onUpdate(false)
            setGlobalState((prev) => ({ ...prev, isSpinning: false, isSparking: false }))
        }
    }
    const isLanguageValid = !Boolean(state.language)

    return (
        <Grid rows="auto 1fr auto" className={styles.main} gap={0}>
            <Heading type="heading1">Add language</Heading>
            <div className={styles.footer}>
                <Grid columns="1fr auto auto" gap={0}>
                    <div></div>

                    {state.isDone && (
                        <Grid gap={0} className={styles.info} columnSpan={3}>
                            Language added
                        </Grid>
                    )}

                    {state.isDeploying && (
                        <Grid columns="1fr auto" columnSpan={2} style={{ marginBottom: '20px', marginTop: '20px' }}>
                            <div />
                            <Grid columns="1fr auto" gap={10}>
                                <Grid>Updating</Grid>
                                <Grid style={{ alignItems: 'center', marginBottom: '3px' }}>
                                    <LoaderDots color="black" />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {state.hasErrors && (
                        <Grid gap={20} className={styles.info}>
                            An error occurred, update failed
                        </Grid>
                    )}

                    {!state.isDeploying && !state.isDone && (
                        <Grid rows="auto auto" columns="1fr auto" columnSpan={3} gap={20}>
                            <Grid columnSpan={2}>
                                <Input
                                    name="langauge"
                                    headerText={'New language'}
                                    placeholder="New language"
                                    style={{ width: 'inherit' }}
                                    value={state.language}
                                    onChange={(e) =>
                                        setState((prev) => ({
                                            ...prev,
                                            language: e.target.value,
                                        }))
                                    }
                                    className={cx({
                                        [styles.requiredInput]: !Boolean(state.language),
                                    })}
                                    readOnly={state.isDeploying}
                                />
                            </Grid>
                            <div />
                            <Button
                                style={{ marginBottom: '10px' }}
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={addLanguage}
                                disabled={isLanguageValid}
                            >
                                Save
                            </Button>
                        </Grid>
                    )}

                    {state.isDone && (
                        <Grid columns="1fr auto" style={{ marginBottom: '10px', marginTop: '20px' }}>
                            <div />
                            <Button
                                className={styles.deploybtn}
                                buttonType={ButtonType.Confirm}
                                onClick={() => onClose(true, !state.hasErrors)}
                            >
                                Close
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
        </Grid>
    )
}

export default NewLanguage
