import { FunctionComponent, useContext, useEffect, useState } from 'react'
import Input from '../../../infratructure/input/Input'
import styles from './NewProduct.module.scss'
import Grid from '../../../infratructure/grid/Grid'
import Heading from '../../../infratructure/heading/Heading'
import Button, { ButtonType } from '../../../infratructure/button/Button'
import { AllSparkContext } from '../../../..'
import Spinner from '../../../infratructure/spinner/Spinner'
import { ProductModel, ProductType } from '../../Model/AllSparkModel'
import cx from 'classnames'
import { Options } from 'react-select'
import Select, { OptionType } from '../../../infratructure/select/Select'
import { Logger } from '../../../utils/Logger'

interface NewProductProps {
    onAdd: (product: ProductModel) => void
}

type NewProductState = {
    isWorking: boolean
    isLoadingHelmChartVersions: boolean
    helmChartVersions:
        | Options<{
              label: string
              value: string
          }>
        | undefined
    productTypes: Options<{
        label: string
        value: ProductType
    }>
    selectedHelmChartVersion: OptionType | undefined
    selectedProductType: { label: string; value: ProductType }
    name: string
    version: string
    description: string
    template: string
    sqlInstallScript: string | null
}

const NewProduct: FunctionComponent<NewProductProps> = ({ onAdd }) => {
    const api = useContext(AllSparkContext)

    const [state, setState] = useState<NewProductState>({
        isWorking: false,
        isLoadingHelmChartVersions: false,
        helmChartVersions: [{ label: 'latest', value: 'latest' }],
        productTypes: [
            { label: ProductType[ProductType.Invision], value: ProductType.Invision },
            { label: ProductType[ProductType.Flow], value: ProductType.Flow },
        ],
        selectedHelmChartVersion: undefined,
        selectedProductType: { label: ProductType[ProductType.Invision], value: ProductType.Invision },
        name: '',
        version: '',
        description: '',
        template: '',
        sqlInstallScript: null,
    })

    useEffect(() => {
        getVersions(ProductType.Invision)
    }, [api])

    const getVersions = async (productType: ProductType) => {
        setState((prev) => ({
            ...prev,
            isLoadingHelmChartVersions: true,
        }))

        try {
            const chartVersions = await api.getVersions(productType)

            if (chartVersions) {
                let versions = [
                    { label: 'latest', value: 'latest' },
                    ...chartVersions.versions.map((v) => ({
                        label: v.version + (v.buildType ? '-' + v.buildType : ''),
                        value: v.version,
                    })),
                ]

                setState((prev) => ({
                    ...prev,
                    isLoadingHelmChartVersions: false,
                    helmChartVersions: versions,
                    selectedProductType: { label: ProductType[productType], value: productType },
                }))
            }
        } catch (error) {
            Logger.logError('Failed getting helm chart versions !', error)
        }
    }

    const handleAdd = async () => {
        try {
            setState((prev) => ({ ...prev, isWorking: true }))

            const addedProduct = await api.createOrUpdateProduct({
                name: state.name,
                productType: state.selectedProductType.value.toString(),
                version: state.version,
                platformVersion: state.selectedHelmChartVersion?.value.toString() || '',
                description: state.description,
                databaseTemplateName: state.template,
                sqlInstallScript: null,
            })

            if (addedProduct) {
                onAdd(addedProduct)
                setState((prev) => ({ ...prev, isWorking: false }))
            }
        } catch (error) {
            Logger.logError(error)
            setState((prev) => ({ ...prev, isWorking: false }))
        }
    }

    const onSelectedHelmChartVersionChange = (version: OptionType) => {
        setState((prev) => ({ ...prev, selectedHelmChartVersion: version }))
    }

    const onSelectedProductTypeChange = async (productType: { label: string; value: ProductType }) => {
        setState((prev) => ({ ...prev, selectedProductType: productType }))
        await getVersions(productType.value)
    }

    const isValid = !(
        Boolean(state.name) &&
        Boolean(state.version) &&
        Boolean(state.selectedHelmChartVersion) &&
        Boolean(state.selectedProductType) &&
        Boolean(state.template)
    )

    return (
        <Grid className={styles.container} rows="auto 1fr auto">
            <Heading type="heading1">Add Product</Heading>
            <Grid rows="1fr auto auto">
                <Grid className={styles.main} gap={20} style={{ marginRight: '20px' }}>
                    <Select
                        headerText="Product Type"
                        isSearchable={false}
                        isClearable={false}
                        options={state.productTypes}
                        value={state.selectedProductType.value}
                        onChange={onSelectedProductTypeChange}
                        placeholder="Select Product Type"
                        isDisabled={state.isLoadingHelmChartVersions}
                    />
                    <Input
                        autoFocus={true}
                        headerText={'Name'}
                        placeholder="Enter a product name"
                        value={state.name || ''}
                        onChange={(e) => setState((prev) => ({ ...prev, name: e.target.value }))}
                        className={cx({
                            [styles.requiredInput]: !Boolean(state.name),
                        })}
                    />
                    <Input
                        headerText={'Version'}
                        placeholder="Enter the product version"
                        value={state.version || ''}
                        onChange={(e) => setState((prev) => ({ ...prev, version: e.target.value }))}
                        className={cx({
                            [styles.requiredInput]: !Boolean(state.version),
                        })}
                    />

                    <Grid gap={4}>
                        <Heading
                            type="normal"
                            className={cx({
                                [styles.requiredInput]: !Boolean(state.selectedHelmChartVersion),
                            })}
                        >
                            Platform Version
                        </Heading>
                        <Grid columns="1fr auto" style={{ alignItems: 'center' }}>
                            <Select
                                isSearchable={false}
                                isClearable={false}
                                options={state.helmChartVersions}
                                value={state.selectedHelmChartVersion?.value}
                                onChange={onSelectedHelmChartVersionChange}
                                placeholder="Select Platform Version"
                                isDisabled={state.isLoadingHelmChartVersions}
                            />
                            {state.isLoadingHelmChartVersions && (
                                <Grid style={{ marginLeft: '9px' }}>
                                    <Spinner />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <Input
                        headerText={'Database template name'}
                        placeholder="Enter the database template name"
                        value={state.template || ''}
                        onChange={(e) => setState((prev) => ({ ...prev, template: e.target.value }))}
                    />

                    <Input
                        headerText={'Product description'}
                        placeholder="Enter the product description"
                        value={state.description || ''}
                        onChange={(e) => setState((prev) => ({ ...prev, description: e.target.value }))}
                    />
                </Grid>
            </Grid>
            <div className={styles.footer}>
                <Grid columns="1fr auto" gap={8} style={{ marginRight: '20px', marginTop: '20px' }}>
                    <div></div>
                    <Button
                        className={styles.deploybtn}
                        buttonType={ButtonType.Confirm}
                        onClick={handleAdd}
                        disabled={isValid}
                    >
                        {state.isWorking ? <Spinner size={18} color={'#FFF'} /> : 'Create'}
                    </Button>
                </Grid>
            </div>
        </Grid>
    )
}

export default NewProduct
